const Config =  {
    AppName: "Waschtisch-Konfigurator",
    AppVersion: "v5.0",
    MwSt : "19%",
    Copyright: '(c) 2021 by Marco Gaib - Grandedesigns.de',
    MwStNum: 19,
    //Update the ServerURL to your public Web Address to use in Production Mode
    ServerURL: 'https://konfigurator.ceramicagrande.de',
    APIURL: 'https://kbackend.ceramicagrande.de',
    TokenName: 'jwttoken'
}
 
export default Config
