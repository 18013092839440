import React from "react";
import "@babylonjs/loaders/glTF";
import Navbar from "../components/ui/navbar";
import Cookies from "js-cookie";
import Config from "../Config";
import { getUser } from "../graphql";
import { useQuery } from "@apollo/client";
import {
  FreeCamera,
  HemisphericLight,
  MeshBuilder,
  Vector3,
  SceneLoader,
  Camera,
  DefaultRenderingPipeline,
  GUI
} from '@babylonjs/core'
import * as BABYLON from "@babylonjs/core";
import SceneComponent from "../components/SceneComponent";
function TestUpload({settings}) { 
  const {
    loading,
    data: userdata,
    error,
  } = useQuery(getUser, {
    variables: { ID: localStorage.getItem("id") },
    
  });
  if (!Cookies.get(Config.TokenName)) {
    return <div>You are not allowed to view this page</div>;
  }
  console.log("settings: ",settings)
  let box
  var camerab
const onSceneReady = (scene) => {
  console.log("SCENE READY: ",scene)
  /* // This creates and positions a free camera (non-mesh)
  var camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene)
  // This targets the camera to scene origin
  camera.setTarget(Vector3.Zero())
  const canvas = scene.getEngine().getRenderingCanvas()
  // This attaches the camera to the canvas
  camera.attachControl(canvas, true)
  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene)
  // Default intensity is 1. Let's dim the light a small amount
  light.intensity = 0.7
  // Our built-in 'box' shape.
  box = MeshBuilder.CreateBox('box', { size: 2 }, scene)
  // Move the box upward 1/2 its height
  box.position.y = 1
  // Our built-in 'ground' shape.
  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene)
  //scene.dispose() */
  SceneLoader.Append("/scenes/","background_scene_babylon.babylon",scene,(scene)=>{
    //var Becken = scene.getMeshByID("Becken");
      
    //var engine = scene.getEngine();
    // show the loading screen
    //engine.hideLoadingUI();

    var camera = scene.cameras[1];
    camera.wheelPrecision = 80;
    camera.minZ = 0.1;
    camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED;
    camera.upperBetaLimit = 1.512;
    camera.lowerBetaLimit = 0.7;
    //camera.upperAlphaLimit = 5.68
    //camera.lowerAlphaLimit = 3.650
    camera.lowerRadiusLimit = 1;
    camera.upperRadiusLimit = 4;
    camera.ellipsoid = new Vector3(1, 1, 1);
    camera.checkCollisions = true;
    scene.switchActiveCamera(camera);
    camera.attachControl(scene.getEngine().getRenderingCanvas(), true);

    var pipeline = new DefaultRenderingPipeline(
      "defaultPipeline", // The name of the pipeline
      true, // Do you want the pipeline to use HDR texture?
      scene, // The scene instance
      [scene.cameras[1], scene.getCameraByID("screenshotcam")] // The list of cameras to be attached to
    );
    pipeline.glowLayerEnabled = false;

    //pipeline.glowLayer.intensity = 0.5;
    //pipeline.glowLayer.blurKernelSize = 50;
    pipeline.imageProcessing.contrast = 1.2;
    pipeline.imageProcessing.exposure = 0.9;

    pipeline.imageProcessing.toneMappingEnabled = false;
    pipeline.imageProcessing.toneMappingType = 1;
    pipeline.imageProcessing.vignetteEnabled = true;
    pipeline.imageProcessing.vignetteWeight = 1.5;
    pipeline.fxaaEnabled = true;
    pipeline.sharpenEnabled = false;

    scene.getMeshByID("booleancube_left").isVisible = false;
    scene.getMeshByID("booleancube_right").isVisible = false;
    scene.getMeshByID("booleancube").isVisible = false;
    scene.getMeshByID("Punkt_left").isVisible = false;
    scene.getMeshByID("Punkt_right").isVisible = false;
    scene.getMeshByID("Punkt").isVisible = false;
    scene.getMeshByID("Rinne_left").isVisible = false;
    scene.getMeshByID("Rinne_right").isVisible = false;
    scene.getMeshByID("Rinne").isVisible = false;
    scene.getMeshByID("Schlitz_left").isVisible = false;
    scene.getMeshByID("Schlitz_right").isVisible = false;
    scene.getMeshByID("Schlitz").isVisible = false;
    scene.getMeshByID("Becken").isVisible = false;
    scene.getMeshByID("Doppelbecken").isVisible = false;
    let cubemat = scene.getMaterialByID("cubemat");
    //cubemat.metallicF0Factor = glossfactor;
    cubemat.roughness = 0;
    var faucetSinkRight = scene.getMeshByID("f_sink").clone("right");
    var faucetWallRight = scene.getMeshByID("f_wall").clone("right");
    var hl = new BABYLON.HighlightLayer("hl1", scene);
    hl.blurHorizontalSize = 0.5;
    hl.blurVerticalSize = 0.5;

    hl.addMesh(
      scene.getMeshByID("ablage"),
      BABYLON.Color3.FromHexString("#ff8000")
    );
    
  })

  var camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene)
  // This targets the camera to scene origin
  //camera.setTarget(Vector3.Zero())
  const canvas = scene.getEngine().getRenderingCanvas()
  // This attaches the camera to the canvas
  console.log(scene.cameras)
  
  
  camera.attachControl(canvas, true)

  
}
/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = (scene) => {
  if (box !== undefined) {
    var deltaTimeInMillis = scene.getEngine().getDeltaTime()
    const rpm = 10
    box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)
  }
}
  return (
    <div>
      <div>
      <Navbar settings={settings} />
      </div>
      <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
      </div>
    </div>
  );
}

export default TestUpload;
