import React from "react";
import { Button, Form, Grid, Icon, Menu, Modal } from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { getLogo, updateSettings } from "../../graphql";
import Config from "../../Config";
import "../../index.css";
import { Link, useNavigate } from "react-router-dom";
import { getSettings, getMe, getUser } from "../../graphql";
import Cookies from "js-cookie";
import CssEditor from "../../pages/csseditor";
export default function Navbar() {
  let navigate = useNavigate();
  const [activeItem, setActiveItem] = React.useState("");
  const [opensettings, setOpensettings] = React.useState(false);
  const [opencss, setOpencss] = React.useState(false);
  const [showconfig, setShowconfig] = React.useState(false);
  const [multiplikator, setMultiplikator] = React.useState(false);
  const { error: seterror, data: setdata } = useQuery(getSettings);
  const { error: meerror, data: medata } = useQuery(getUser, {
    variables: { ID: parseInt(localStorage.getItem("id")) },
  });

  React.useEffect(() => {}, [opencss]);
  const [updateSet] = useMutation(updateSettings, {
    refetchQueries: [{ query: getSettings }],
  });
  const [showlogo, setShowlogo] = React.useState(false);
  React.useEffect(() => {
    if (activeItem === "new_configuration") {
      localStorage.removeItem("SinkConfig")
      localStorage.setItem("isloaded",false)
      
      navigate("/configurator");
      navigate(0);
      
    }
    if (activeItem === "dashboard") {
      navigate("/dashboard");
    }
    if (activeItem === "logout") {
      Cookies.remove(Config.TokenName);
      navigate("/");
    }
    if (activeItem === "test") {
      navigate("/test");
    }
    if (activeItem === "CSS-Editor") {
      setOpencss(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  React.useEffect(() => {
    if (setdata) {
      setShowconfig(setdata.einstellungen.data.attributes.Konfiguratoranzeigen);
      setShowlogo(setdata.einstellungen.data.attributes.Logoanzeigen);
      setMultiplikator(
        setdata.einstellungen.data.attributes.Preismultiplikator
      );
    }
  }, [setdata]);

  const { loading, data, error } = useQuery(getLogo);
  //var logo =""
  if (loading) {
    return <div>Lade Daten.....</div>;
  }
  if (error) {
    return <div>Logo kann nicht geladen werden</div>;
  }
  function handleItemClick(event, name) {
    setActiveItem(name.name);
  }

  function handleShowlogo(event) {
    setShowlogo(!showlogo);
  }
  function handleShowconfig(event) {
    setShowconfig(!showconfig);
  }
  function handleMultiplikator(event) {
    setMultiplikator(event.target.value);
  }

  function handleOpenCss(event, name) {
    setOpencss(true);
  }

  if (seterror) {
    return <div>Einstellungen können nicht geladen werden</div>;
  }
  if (meerror) {
    return (
      <div>Benutzerdaten können nicht geladen werden {meerror.message}</div>
    );
  }
  if (setdata) {
    if (medata) {
      if (medata.usersPermissionsUser.data) {
        console.log(medata);
        return (
          <div className="navtable">
            <div className="navbody">
              <div className="navbar">
                <div className="navbarcell shrinkcell">
                  {showlogo ? (
                    <img
                      className="navbarlogo"
                      src={
                        Config.APIURL +
                        setdata.einstellungen.data.attributes.Logo.data
                          .attributes.formats.thumbnail.url
                      }
                      alt="logo"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="navbarcell shrinkcell">
                  <center>
                    <h3>{setdata.einstellungen.data.attributes.Titel}</h3>
                    <p className="copyrightNavbar">
                      Version {process.env.REACT_APP_VERSION}
                    </p>
                  </center>
                </div>
                <div className="navbarcell menucell">
                  <Menu>
                    <Menu.Item
                      name="new_configuration"
                      active={activeItem === "new_configuration"}
                      onClick={(event, name) => handleItemClick(event, name)}
                      color={"green"}
                    >
                      Konfigurator
                    </Menu.Item>
                    <Menu.Item
                      name="test"
                      active={activeItem === "test"}
                      onClick={(event, name) => handleItemClick(event, name)}
                      color={"green"}
                    >
                      test
                    </Menu.Item>

                    <Menu.Item
                      name="dashboard"
                      active={activeItem === "dashboard"}
                      onClick={(event, name) => handleItemClick(event, name)}
                    >
                      Übersicht
                    </Menu.Item>
                    {medata.usersPermissionsUser.data.attributes.role.data
                      .attributes.name === "Verwalter" ? (
                      <Menu.Item
                        name="CSS-Editor"
                        active={activeItem === "cssedit"}
                        onClick={(event, name) => handleOpenCss(event, name)}
                      >
                        CSS-Editor
                      </Menu.Item>
                    ) : null}

                    <Menu.Item
                      name="logout"
                      active={activeItem === "logout"}
                      onClick={(event, name) => handleItemClick(event, name)}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                </div>
                <div className="navbarcell shrinkcell">
                  <small>
                    <b>Nutzer:</b>{" "}
                    {medata.usersPermissionsUser.data.attributes.username}{" "}
                    <br />
                    <b>Name:</b>{" "}
                    {medata.usersPermissionsUser.data.attributes.Vorname}{" "}
                    {medata.usersPermissionsUser.data.attributes.Nachname}
                    <br />
                    <b>Typ:</b>{" "}
                    {
                      medata.usersPermissionsUser.data.attributes.role.data
                        .attributes.name
                    }
                  </small>
                </div>
                <div className="navbarcell textright shrinkcell">
                  {medata.usersPermissionsUser.data.attributes.role.data
                    .attributes.name === "Verwalter" ? (
                    <Button
                      icon
                      color="green"
                      onClick={() => setOpensettings(true)}
                    >
                      <Icon name="settings" />
                    </Button>
                  ) : null}
                </div>

                <Modal
                  onClose={() => setOpensettings(false)}
                  onOpen={() => setOpensettings(true)}
                  open={opensettings}
                >
                  <Modal.Header>Einstellungen</Modal.Header>

                  <Modal.Content>
                    <Modal.Description>
                      <Form>
                        <Grid columns={2}>
                          <Grid.Column>
                            <Form.Checkbox
                              label="Logo anzeigen"
                              defaultChecked={
                                setdata.einstellungen.data.attributes
                                  .Logoanzeigen
                              }
                              onChange={(event) => handleShowlogo(event)}
                            />
                            <Form.Checkbox
                              label="Konfigurator für nicht eingeloggte Benutzer anzeigen"
                              name="konfigcheck"
                              defaultChecked={
                                setdata.einstellungen.data.attributes
                                  .Konfiguratoranzeigen
                              }
                              onChange={(event) => handleShowconfig(event)}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Form.Input
                              label="Preismultiplikator"
                              defaultValue={String(
                                setdata.einstellungen.data.attributes
                                  .Preismultiplikator
                              ).replace(".", ",")}
                              onChange={(event) => handleMultiplikator(event)}
                            />
                          </Grid.Column>
                        </Grid>
                      </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      content="Abbrechen"
                      onClick={() => setOpensettings(false)}
                    />
                    <Button
                      content="Speichern"
                      color="green"
                      onClick={() => {
                        updateSet({
                          variables: {
                            Konfiguratoranzeigen: Boolean(showconfig),
                            Logoanzeigen: Boolean(showlogo),
                            Preismultiplikator: parseFloat(
                              multiplikator.replace(",", ".")
                            ),
                          },
                        });
                        setOpensettings(false);
                      }}
                    />
                  </Modal.Actions>
                </Modal>

                <Modal
                  onClose={() => setOpencss(false)}
                  onOpen={() => setOpencss(true)}
                  open={opencss}
                >
                  <Modal.Header>CSS Style-Editor</Modal.Header>

                  <Modal.Content>
                    <Modal.Description>
                      <CssEditor settings={setdata} />
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      color="blue"
                      content="Schließen"
                      onClick={() => setOpencss(false)}
                    />
                  </Modal.Actions>
                </Modal>
              </div>
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <div>Ein Fehler ist aufgetreten!</div>;
    }
  }

  return <div>Ein Fehler ist aufgetreten! </div>;
}
