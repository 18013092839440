import React from "react";
import { Button, Form, Card, Message, Container } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getLogo, getSettings } from "../graphql";
import Config from "../Config";
import { useMutation } from "@apollo/client";
import { UserLogin, getMe } from "../graphql";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import * as Yup from "yup";
//@TODO: Calling / shows the Login, although the user is logged in

export default function Login() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("Ungültige Email Adresse")
        .required("Email Adresse ist erforderlich"),
      password: Yup.string().required("Passwort ist erforderlich"),
    }),
    onSubmit: (values) => {
      login({
        variables: {
          Identifier: String(values.username),
          Password: String(values.password),
        },
        onError: (error) => {
          console.log(error);
          setOpen(true);
        },

        refetchQueries: [{ query: getMe, getSettings }],
      }).then((data) => {
        Cookies.set(Config.TokenName, data.data.login.jwt);
        localStorage.setItem("token", data.data.login.jwt);
        localStorage.setItem("id", data.data.login.user.id);
        navigate("/dashboard");
      });
    },
  });

  const [login] = useMutation(UserLogin);

  const { loading, data, error } = useQuery(getLogo);
  if (loading) {
    return <div>Loading data...</div>;
  }
  if (error) {
    return <div>Error loading data... {error.message}</div>;
  }

  return (
    <div>
      {open && (
        <Container>
          <br />
          <Message color="red">
            <Message.Header>Fehlerhafte Anmeldedaten</Message.Header>
            <p>Benutzername oder Kennwort falsch!</p>
          </Message>
        </Container>
      )}
      <Card className="loginform" color="blue">
        <Card.Content className="loginheader">
          <center>
            <img
              className="loginlogo"
              src={
                Config.APIURL +
                data.einstellungen.data.attributes.Logo.data.attributes.formats
                  .thumbnail.url
              }
              alt="logo"
            />

            <h2 className="logintitle">Waschtischkonfigurator Backend</h2>
          </center>
        </Card.Content>
        <Card.Content>
          <center>
            <h4>Login</h4>
          </center>
          <br />
          <Form onSubmit={formik.handleSubmit}>
            <Form.Input
              placeholder="Email Adresse"
              onChange={formik.handleChange}
              error={
                formik.errors.username
                  ? {
                      content: formik.errors.username,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
              name="username"
              label="Email Adresse"
              value={formik.values.username}
            />

            <Form.Input
              placeholder="Passwort"
              label="Passwort"
              type="password"
              onChange={formik.handleChange}
              error={
                formik.errors.password
                  ? {
                      content: formik.errors.password,
                    }
                  : null
              }
              onBlur={formik.handleBlur}
              name="password"
              value={formik.values.password}
            />

            <Button type="submit">Login</Button>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
}
