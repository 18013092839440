import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { updateUser, getUser, createUser, getRoles } from "../../graphql";
import { Container, Form, Label } from "semantic-ui-react";
import gql from "graphql-tag";
import UpdateFilialUsers from "./updatefilialusers";

const MitarbeiterForm = React.forwardRef((props, ref) => {
  const [isNew, setIsNew] = useState(false);

  React.useEffect(() => {}, [isNew]);

  //create function to set initial values
  const setInitialValues = (values) => {
    setUsername(values.username);
    setVorname(values.Vorname);
    setNachname(values.Nachname);
    setEmail(values.email);
    setRole(values.role.data.id);
    setBlocked(values.blocked);
    setTelefon(values.Telefon);
    setMobil(values.Mobil);
  };

  const { data: rolesData } = useQuery(getRoles, {
    onCompleted: (data) => {},
  });

  const {
    loading: floading,
    error: ferror,
    data: fdata,
  } = useQuery(getUser, {
    variables: { ID: props.id },
    onCompleted: (data) => {
      if (props.new === false) {
        setInitialValues(data.usersPermissionsUser.data.attributes);
        setIsNew(false);
      } else {
        setIsNew(true);
      }
    },
  });

  const [username, setUsername] = useState("");

  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [email, setEmail] = useState("");
  const [passwort, setPasswort] = useState("");
  const [role, setRole] = useState("");
  const [blocked, setBlocked] = useState(false);
  const [telefon, setTelefon] = useState("");
  const [mobil, setMobil] = useState("");
  const [checkboxValues, setCheckboxValues] = React.useState({});
  const [createMitarbeiterMutation] = useMutation(createUser, {
    onCompleted: (newdata) => updateValues(newdata),
  });

  const [updateMitarbeiterMutation] = useMutation(updateUser, {
    onCompleted: (newdata) => {
      updateValues(newdata);
    },
  });
  const updateValues = (newdata) => {
    setUsername(newdata.updateUsersPermissionsUser.data.attributes.username);
    setVorname(newdata.updateUsersPermissionsUser.data.attributes.Vorname);
    setNachname(newdata.updateUsersPermissionsUser.data.attributes.Nachname);
    setEmail(newdata.updateUsersPermissionsUser.data.attributes.Email);
    setPasswort(newdata.updateUsersPermissionsUser.data.attributes.password);
    setBlocked(newdata.updateUsersPermissionsUser.data.attributes.blocked);
    setTelefon(newdata.updateUsersPermissionsUser.data.attributes.Telefon);
    setMobil(newdata.updateUsersPermissionsUser.data.attributes.Mobil);
  };

  const handleSubmit = () => {
    //e.preventDefault();

    if (isNew === false) {
      if (passwort === "") {
        updateMitarbeiterMutation({
          variables: {
            ID: props.id,
            username: username,
            Vorname: vorname,
            Nachname: nachname,
            Email: email,
            Role: role,
            Blocked: blocked,
            Mobil: mobil,
            Telefon: telefon,
          },
          refetchQueries: [{ query: props.refetch }],
        });
      } else {
        updateMitarbeiterMutation({
          variables: {
            ID: props.id,
            username: username,
            Vorname: vorname,
            Nachname: nachname,
            Email: email,
            Role: role,
            Password: passwort,
            Blocked: blocked,
            Mobil: mobil,
            Telefon: telefon,
          },
          refetchQueries: [{ query: props.refetch }],
        });
      }
    } else {
      createMitarbeiterMutation({
        variables: {
          username: username,
          Vorname: vorname,
          Nachname: nachname,
          email: email,
          Password: passwort,
          Blocked: blocked,
          Mobil: mobil,
          Telefon: telefon,
        },
        onCompleted: (newdata) => {
          console.log("newdata", newdata);
          updateMitarbeiterMutation({
            variables: {
              ID: newdata.register.user.id,
              Blocked: blocked,
              Role: role,
            },
            refetchQueries: [{ query: props.refetch }],
          });
        },
      });
    }
  };

  React.useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  React.useEffect(() => {}, [checkboxValues]);

  if (floading) return <p>Loading...</p>;
  if (ferror) return <p>Error :(</p>;
  if (fdata) {
    if (rolesData) {
      const roles = [];
      for (let role of rolesData.usersPermissionsRoles.data) {
        if (
          role.attributes.name === "Vertrieb" ||
          role.attributes.name === "Verwalter"
        ) {
          if (fdata.usersPermissionsUser.data) {
            if (
              fdata.usersPermissionsUser.data.attributes.role.data.id ===
              role.id
            ) {
              roles.push({
                key: role.id,
                value: role.id,
                text: role.attributes.name,
                selected: true,
              });
            } else {
              if(role.attributes.name === "Vertrieb"){
                roles.push({
                  key: role.id,
                  value: role.id,
                  text: role.attributes.name,
                  selected: true,
                });
              }else{
                roles.push({
                  key: role.id,
                  value: role.id,
                  text: role.attributes.name,
                });
              }
              
             }
          }else{
            roles.push({
              key: role.id,
              value: role.id,
              text: role.attributes.name,
            });
          }
        }
      }
      return (
        <Container>
          <br />
          <Form>
            <Form.Input
              label="Username"
              required={true}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Group>
              <Form.Input
                label="Vorname"
                required={true}
                value={vorname}
                width={8}
                onChange={(e) => setVorname(e.target.value)}
              />
              <Form.Input
                label="Nachname"
                required={true}
                value={nachname}
                width={8}
                onChange={(e) => setNachname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Mobil"
                value={mobil}
                onChange={(e) => setMobil(e.target.value)}
                width={5}
              />
              <Form.Input
                label="Telefon"
                value={telefon}
                onChange={(e) => setTelefon(e.target.value)}
                width={5}
              />
              <Form.Input
                label="Email"
                required={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                width={5}
              />
            </Form.Group>
            <Form.Select
              label="Rolle"
              options={roles}
              value={role}
              onChange={(e, { value }) => setRole(value)}
            />
            <Form.Input
              type="password"
              label="Neues Passwort (min. 6 Zeichen)"
              onChange={(e) => setPasswort(e.target.value)}
            />

            <Form.Group>
              <Form.Checkbox
                label="Mitarbeiter gesperrt"
                checked={blocked}
                onChange={(e) => setBlocked(!blocked)}
              />
            </Form.Group>
          </Form>
        </Container>
      );
    }
  }
  return <></>;
});

export default MitarbeiterForm;
