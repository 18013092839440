// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__line {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n\n.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__input {\n  border: unset;\n  background-color: inherit;\n  flex-grow: 1;\n  width: 100px; }\n\n.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__valid {\n  background-color: white; }\n\n.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__invalid {\n  background-color: rgba(255, 204, 204, 0.8); }\n", "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/TextInputWithSubmit.modules.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;;AAGvB;EACI,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY,EAAA;;AAGhB;EACI,uBAAuB,EAAA;;AAG3B;EACI,0CAA0C,EAAA","sourcesContent":[".line {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.input {\r\n    border: unset;\r\n    background-color: inherit;\r\n    flex-grow: 1;\r\n    width: 100px;\r\n}\r\n\r\n.valid {\r\n    background-color: white;\r\n}\r\n\r\n.invalid {\r\n    background-color: rgba(255, 204, 204, 0.8);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__line",
	"input": "---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__input",
	"valid": "---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__valid",
	"invalid": "---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-modules__invalid"
};
export default ___CSS_LOADER_EXPORT___;
