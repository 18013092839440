import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import Viewswitch from "../components/Viewswitch";
import Canvas from "../components/Canvas.jsx";
import Ui from "../components/Ui";
import Pricetag from "../components/Pricetag";
import SinkConfig from "../components/SinkConfig";
import Navbar from "../components/ui/navbar";
import "../components/Scene";
import { Button, Card, Grid, Icon } from "semantic-ui-react";
import Config from "../Config";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getUser } from "../graphql";
import { useQuery } from "@apollo/client";
import { Slider } from "primereact/slider";
import { SCENE } from "../components/Scene";
import { Panel } from "primereact/panel";
//import '../App.css';

export default function Configurator({ settings }) {
  const {
    loading,
    data: userdata,
    error,
  } = useQuery(getUser, {
    variables: { ID: localStorage.getItem("id") },
    onCompleted: (data) => {
      if (data.usersPermissionsUser.data) {
        setShowPrice(data.usersPermissionsUser.data.attributes.Preisanzeigen);
      }
    },
  });
  const [brightness, setBrightness] = useState(SCENE.brightness);
  const [sunbrightness, setSunBrightness] = useState(SCENE.brightness);

  React.useEffect(() => {
    console.log("Updating brightness", brightness);
    SCENE.brightness = brightness;
    SCENE.sunbrightness = sunbrightness;
  }, [brightness, sunbrightness]);

  let navigate = useNavigate();
  const [endprice, setEndprice] = useState(0);
  const [screenshot, setScreenshot] = useState("");
  const [loggedin, setLoggedin] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  if (Cookies.get(Config.TokenName) && loggedin === false) {
    setLoggedin(true);
  }
  

  if (settings.Konfiguratoranzeigen === false && loggedin === false) {
    return (
      <Card className="loginform" color="blue">
        <Card.Content className="loginheader">
          <center>
            <img
              className="loginlogo"
              src={
                Config.APIURL +
                settings.Logo.data.attributes.formats.thumbnail.url
              }
              alt="logo"
            />
          </center>
        </Card.Content>
        <Card.Content>
          <center>
            <h2 className="error">
              <Icon name="meh outline" color="red" />
              Ooops!{" "}
            </h2>
            <p className="message">
              Leider steht unser Waschtischkonfigurator derzeit nicht zur
              Verfügung.
            </p>
            <Button
              color="green"
              onClick={() => {
                navigate("/");
              }}
            >
              Anmelden
            </Button>
          </center>
          <br />
        </Card.Content>
      </Card>
    );
  }
  return (
    <div className="configcontent">
      {loggedin ? <Navbar settings={settings} /> : <div></div>}

      {/* Main View Grid */}
      <div className="ui stackable centered grid top aligned">
        {/* UI Window */}
        <div className="five wide column Viewcontainer">
          <Ui
            SinkConfig={SinkConfig}
            screenshot={screenshot}
            setScreenshot={setScreenshot}
            userdata={userdata}
          />
        </div>
        {/* Preview Window */}
        <div className="ten wide column Viewcontainer">
          {/* View Header */}
          <div className="ui green ribbon label">Vorschau</div>
          {/* VewSwitch to toggle Theme */}
          <div className="grid">
            <div className="col-3 borderedgrid middle">
              <Viewswitch
                id="sw_view"
                text="Neutrale Wand anzeigen"
                class="ui"
              />
            </div>
            <div className="col-5"></div>
            <div className="col-4">
              <div className="grid borderedgrid">
                <div className="col-2 middle">
                  <i className="pi pi-sun text-2xl text-yellow-600"></i>
                </div>
                <div className="col-8 middle">
                  <div class="grid">
                    <div class="col-4">Spotlights</div>
                    <div class="col-8">
                      <Slider
                        id="brightness"
                        value={brightness}
                        onChange={(e) => setBrightness(e.value)}
                        label="Helligkeit"
                        min={0}
                        max={100}
                      />
                    </div>
                    <div class="col-4">Hauptlicht</div>
                    <div class="col-8">
                      <Slider
                        id="sunbrightness"
                        value={sunbrightness}
                        onChange={(e) => setSunBrightness(e.value)}
                        label="Helligkeit"
                        min={0}
                        max={100}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="ui divider" />
          {/* 3D Canvas */}
          <Canvas
            endprice={endprice}
            setEndprice={setEndprice}
            setScreenshot={setScreenshot}
          />
          {loggedin ? (
            showPrice ? (
              <Pricetag endprice={endprice} />
            ) : null
          ) : null}
          {loggedin ? null : (
            <div className="manual">
              <center>
                <p>
                  Bedienung: <b>Zoom:</b> (Mausrad), <b>Rotation:</b> (linke
                  Maustaste gedrückt halten und ziehen)
                </p>
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
