import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { getMaterials, deleteMaterial } from "../../graphql";
import {
  Table,
  Header,
  Label,
  Icon,
  Button,
  Form,
  Grid,
  Popup,
} from "semantic-ui-react";
import { convertTimestamp } from "../../helpers";
import { Image, Modal, Confirm } from "semantic-ui-react";
import React from "react";
import { getSingleUserConfiguration } from "../../graphql";
import { currentConfig } from "../../helpers";
import Config from "../../Config";
import MaterialForm from "./update_material_form";

export default function MaterialienTable() {
  const [isNew, setIsNew] = React.useState(false);
  const [configid, setConfigid] = React.useState(-1);
  const [config, setConfig] = React.useState(currentConfig);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const form = React.useRef(null);
  const openModal = (id) => {
    setId(id);
    setIsNew(false);
    setOpen(true);
  };

  const Tischbreite = React.createRef();
  const Tischtiefe = React.createRef();
  const Beckenbreite = React.createRef();
  const Beckentiefe = React.createRef();
  const Beckenposition = React.createRef();
  const PreisMwst = React.createRef();
  const UUID = React.createRef();
  const Valid = React.createRef();
  const Bohrung = React.createRef();
  const Einzelbecken = React.createRef();
  const Untertischablage = React.createRef();
  const Anmerkung = React.createRef();
  const Abflusstyp = React.createRef();
  const Vorschau = React.createRef();
  const KVorname = React.createRef();
  const KNachnahme = React.createRef();
  const KFirmenname = React.createRef();
  const KStrasse = React.createRef();
  const KPLZ = React.createRef();
  const KOrt = React.createRef();
  const KTelefon = React.createRef();
  const KMobil = React.createRef();
  const KEmail = React.createRef();
  const MPreis = React.createRef();
  const MPoliert = React.createRef();
  const MName = React.createRef();
  const MBild = React.createRef();
  const UVorname = React.createRef();
  const UNachname = React.createRef();
  const UFiliale = React.createRef();
  const UTelefon = React.createRef();
  const UMobil = React.createRef();

  const [confopen, setConfopen] = React.useState(false);
  const {
    loading: confloading,
    data: confdata,
    error: conferror,
    refetch,
  } = useQuery(getMaterials);

  const [getSingleConfig, { data: condata }] = useLazyQuery(
    getSingleUserConfiguration,
    {
      variables: { ID: configid },
      onCompleted: () => {
        setConfig(condata);
      },
    }
  );

  const [deleteMaterialMutation] = useMutation(deleteMaterial, {
    refetchQueries: [{ query: getMaterials }],
  });

  function handleDelete(id) {
    deleteMaterialMutation({ variables: { ID: id } });
  }

  React.useEffect(() => {
    if (configid !== -1) {
      setOpen(true);
      getSingleConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configid]);

  /* function handletischbreite(event) {
    console.log(config);
    let update = {
      konfiguration: {
        data: { attributes: { Tischbreite: parseInt(event.target.value) } },
      },
    };
    setConfig((config) => ({
      ...config,
      ...update,
    }));
    console.log(config);
  } */

  function saveConfig() {
    setOpen(false);
  }

  if (confdata) {
    return (
      <div>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          

          {isNew ? (
            <Modal.Header>Material hinzufügen</Modal.Header>
          ) : (
            <Modal.Header>Material aktualisieren</Modal.Header>
          )}
          <Modal.Content>
            <Modal.Description>
              <MaterialForm id={id} ref={form} refetch={getMaterials} new={isNew}/>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            <Button
              content="Speichern"
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                form.current.handleSubmit();
                setOpen(false);
              }}
              positive
            />
          </Modal.Actions>
        </Modal>
        <Header>
          <span>
            <Popup
              position="right center"
              content="Neues Material anlegen"
              trigger={<Button icon="plus" color="blue" onClick={() => {setIsNew(true); setOpen(true)}} />}
            />
          </span>{" "}
          Materialien
          <Label color="yellow" circular>
            {confdata.materials.data.length}
          </Label>
        </Header>
        <div  className="viewtable">
        <Table striped color="yellow">
          <Table.Header>
            <Table.Row>
              <Table.Cell className="cellheader">Bild</Table.Cell>
              <Table.Cell className="cellheader">Name</Table.Cell>
              <Table.Cell className="cellheader">m² Preis</Table.Cell>
              <Table.Cell className="cellheader">Poliert</Table.Cell>
              <Table.Cell className="cellheader cellleftborder">Aktionen</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {confdata.materials.data.map((conf) => (
              <Table.Row key={conf.id}>
                <Table.Cell>
                  <Image
                    src={
                      Config.APIURL +
                      conf.attributes.Bild.data.attributes.formats.thumbnail.url
                    }
                    size="mini"
                    circular
                  />
                </Table.Cell>
                <Table.Cell>{conf.attributes.Name}</Table.Cell>
                <Table.Cell>{conf.attributes.Preis} €</Table.Cell>
                <Table.Cell>
                  {conf.attributes.Poliert ? "Ja" : "Nein"}
                </Table.Cell>
                <Table.Cell className="cellleftborder">
                  <Button
                    color="blue"
                    icon
                    size="tiny"
                    onClick={() => openModal(conf.id)}
                  >
                    <Icon name="edit" />
                  </Button>
                  <Button
                    color="red"
                    icon
                    size="tiny"
                    onClick={() => handleDelete(conf.id)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        </div>
        <Confirm
          open={confopen}
          onCancel={() => {
            setConfopen(false);
          }}
          onConfirm={() => {
            setConfopen(false);
            setOpen(false);
          }}
          content="Möchten Sie diese Konfiguration jetzt kostenpflichtig bestellen?"
          cancelButton="Abbrechen"
          confirmButton="Jetzt bestellen"
        />
      </div>
    );
  }
  if (confloading) {
    return <div>Suche Konfigurationen...</div>;
  }
  if (conferror) {
    return <div>Keine Konfigurationen gefunden!</div>;
  }
}
