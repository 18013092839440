import React from "react";
import { useQuery } from "@apollo/client";
import { Dimmer, Form, Header, Loader } from "semantic-ui-react";
import { getUsers } from "../../graphql";
function UpdateFilialUsers({ checkboxValues, setCheckboxValues}) {
  
  const { loading, error, data } = useQuery(getUsers);
  
  const handleCheckboxChange = (event, { name, checked }) => {
    setCheckboxValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };
  
  if (loading) return <Dimmer><Loader content="Lade Daten"/></Dimmer>;
  if (error) return <p>Error :(</p>;


  return (
    <div className="userselection">
      <Form size={"tiny"}>
        {data.usersPermissionsUsers.data.map((user) => (
          <Form.Checkbox
            key={user.id}
            label={
              user.attributes.Vorname +
              " " +
              user.attributes.Nachname +
              " (" +
              user.attributes.role.data.attributes.name +
              ")"
            }
            value={user.id}
            name={user.id}
            checked={checkboxValues[user.id] || false}
            onChange={handleCheckboxChange}
          />
        ))}
      </Form>
    </div>
  );
}

export default UpdateFilialUsers;
