(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@babylonjs/core"), require("@babylonjs/gui"));
	else if(typeof define === 'function' && define.amd)
		define("@babylonjs/gui-editor", ["@babylonjs/core", "@babylonjs/gui"], factory);
	else if(typeof exports === 'object')
		exports["@babylonjs/gui-editor"] = factory(require("@babylonjs/core"), require("@babylonjs/gui"));
	else
		root["GUIEDITOR"] = factory(root["BABYLON"], root["BABYLON"]["GUI"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), (__WEBPACK_EXTERNAL_MODULE_core_Misc_observable__, __WEBPACK_EXTERNAL_MODULE_gui_2D_controls_container__) => {
return 