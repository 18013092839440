import React from "react";
import SinkConfig from "./SinkConfig";
import { Button, Modal, Form } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { SCENE } from "./Scene";
import { createConfiguration, updateConfiguration } from "../graphql";
import { calcArea } from "../helpers";
import { fetchImage } from "../helpers";
import { UPLOAD_IMAGE_MUTATION } from "../graphql";
import Cookies from "js-cookie";
import Config from "../Config";
const Requestform = (props) => {
  let btn = "Konfiguration speichern...";
  const [loggedin, setLoggedin] = React.useState(false);
  React.useEffect(() => {
    if (!Cookies.get(Config.TokenName)) {
      setLoggedin(false);
    } else {
      setLoggedin(true);
    }
  }, []);

  React.useEffect(() => {}, [localStorage.getItem("loadConfig")]);

  console.log(localStorage.getItem("isloaded"));
  if (localStorage.getItem("isloaded") === "true") {
    console.log("Updating config");
    btn = "Konfiguration aktualisieren";
  } else {
    console.log("is new config");
  }

  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComment] = React.useState("");
  //const [loading, setLoading] = React.useState("disabled")
  const [createConfig, { error: conferror }] = useMutation(
    createConfiguration,
    {
      onError: () => {
        console.log(conferror.message);
      },
    }
  );
  const [updateConfig, { error: conferror2 }] = useMutation(
    updateConfiguration,
    {
      onError: () => {
        console.log(conferror2.message);
      },
    }
  );

  const [uploadImage, { data: imgdata }] = useMutation(UPLOAD_IMAGE_MUTATION);
  var uuid = uuidv4();

  var validdate = String(
    new Date(new Date().setDate(new Date().getDate() + 14)).toISOString()
  );
  validdate = validdate.substring(0, validdate.indexOf("T"));
  const sinktype = { 1: "Punkt", 2: "Rinne", 3: "Schlitz" };
  function sendRequest() {
    //setLoading("active")
    /* if (company === ""){
        firma = firstName + " " + lastName
      }else{
        firma=company
      } */
    console.log(
      calcArea(
        SinkConfig.tableWidth,
        SinkConfig.tableDepth,
        SinkConfig.tableHeight,
        SinkConfig.sinkWidth,
        SinkConfig.beckenTiefe,
        SinkConfig.beckenHoehe
      )
    );

    handleUpload();
  }

  const handleUpload = async () => {
    const blob = await fetchImage(SinkConfig.materialimage);
    if (blob) {
      const formData = new FormData();
      formData.append(
        "operations",
        JSON.stringify({
          query: UPLOAD_IMAGE_MUTATION.loc.source.body,
          variables: { file: null },
        })
      );
      formData.append("map", JSON.stringify({ 0: ["variables.file"] }));
      formData.append("0", blob, "image.jpg");
      uploadImage({
        variables: { file: blob },
        onCompleted: (data) => {
          createConfig({
            variables: {
              Tischbreite: parseInt(SinkConfig.tableWidth),
              Tischtiefe: parseInt(SinkConfig.tableDepth),
              Beckenbreite: parseInt(SinkConfig.sinkWidth),
              Beckentiefe: parseInt(SinkConfig.beckenTiefe),
              Beckenposition: parseInt(SinkConfig.sinkPos),
              UUID: uuid,
              Valid: validdate,
              Bohrung: Boolean(SinkConfig.sinkMontage),
              Einzelbecken: Boolean(SinkConfig.singleSink),
              Abflusstyp: sinktype[SinkConfig.sinkType + 1],
              Untertischablage: Boolean(SinkConfig.withUnderTable),
              Anmerkung: comment,
              PreisMwst: parseFloat(SinkConfig.endpreisfloat.toFixed(2)),
              MPreis: parseFloat(SinkConfig.materialprice),
              MName: String(SinkConfig.materialname),
              MPoliert: Boolean(SinkConfig.materialglossy),
              Mid: parseInt(SinkConfig.materialid),
              UserID: parseInt(localStorage.getItem("id")),
              KVorname: String(firstName),
              KNachname: String(lastName),
              KEmail: String(email),
              KFirmenname: String(company),
              Materialpreis: parseFloat(SinkConfig.endpreisMaterial),
              Produktionspreis: parseFloat(SinkConfig.endpreisHaendler),
              Bild: data.upload.data.id,
            },
          });
        },
      });
      console.log(blob);
    }
  };

  const handleUpdate = async () => {
    const blob = await fetchImage(SinkConfig.materialimage);
    if (blob) {
      const formData = new FormData();
      formData.append(
        "operations",
        JSON.stringify({
          query: UPLOAD_IMAGE_MUTATION.loc.source.body,
          variables: { file: null },
        })
      );
      formData.append("map", JSON.stringify({ 0: ["variables.file"] }));
      formData.append("0", blob, "image.jpg");
      uploadImage({
        variables: { file: blob },
        onCompleted: (data) => {
          updateConfig({
            variables: {
              ID: parseInt(localStorage.getItem("loadedid")),
              Tischbreite: parseInt(SinkConfig.tableWidth),
              Tischtiefe: parseInt(SinkConfig.tableDepth),
              Beckenbreite: parseInt(SinkConfig.sinkWidth),
              Beckentiefe: parseInt(SinkConfig.beckenTiefe),
              Beckenposition: parseInt(SinkConfig.sinkPos),
      
              Bohrung: Boolean(SinkConfig.sinkMontage),
              Einzelbecken: Boolean(SinkConfig.singleSink),
              Abflusstyp: sinktype[SinkConfig.sinkType + 1],
              Untertischablage: Boolean(SinkConfig.withUnderTable),
      
              PreisMwst: parseFloat(SinkConfig.endpreisfloat.toFixed(2)),
              MPreis: parseFloat(SinkConfig.materialprice),
              MName: String(SinkConfig.materialname),
              MPoliert: Boolean(SinkConfig.materialglossy),
              Mid: parseInt(SinkConfig.materialid),
      
              Materialpreis: parseFloat(SinkConfig.endpreisMaterial),
              Produktionspreis: parseFloat(SinkConfig.endpreisHaendler),
              Bild: data.upload.data.id
            },
          });
        },
      });
    }
  };

  function handleFirstName(event) {
    setFirstName(event.target.value);
  }
  function handleLastName(event) {
    setLastName(event.target.value);
  }
  function handleCompany(event) {
    setCompany(event.target.value);
  }
  function handleEmail(event) {
    setEmail(event.target.value);
  }
  function handleComment(event) {
    setComment(event.target.value);
  }
  /* function handleCheckbox(event) {
        console.log(event.target.checked)
    } */
  if (localStorage.getItem("isloaded") === "true") {
    return (
      <Button
        size="mini"
        onClick={() => handleUpdate()}
        className="ui button green"
      >
        {btn}
      </Button>
    );
  }
  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        SCENE.makeScreenshot = true;
        setOpen(true);
      }}
      open={open}
      trigger={
        loggedin ? (
          <Button size="mini" className="ui button green">
            {btn}
          </Button>
        ) : (
          <div></div>
        )
      }
    >
      <Modal.Header>
        <i className="shopping cart orange icon"></i>Angebot speichern unter
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div>
            <b>
              Bitte geben Sie die Kundendaten ein, um das Angebot speichern zu
              können.
            </b>
            <br />
            <br />
          </div>

          <Form>
            <Form.Field>
              <label>Vorname *</label>
              <input placeholder="Vorname" onChange={handleFirstName} />
            </Form.Field>
            <Form.Field>
              <label>Nachname *</label>
              <input placeholder="Nachname" onChange={handleLastName} />
            </Form.Field>
            <Form.Field>
              <label>Firma (optional)</label>
              <input placeholder="Firma" onChange={handleCompany} />
            </Form.Field>
            <Form.Field>
              <label>Ihre E-Mail Adresse *</label>
              <input placeholder="E-Mail" onChange={handleEmail} />
            </Form.Field>
            <Form.Field>
              <label>Bemerkungen</label>
              <textarea rows="3" onChange={handleComment}></textarea>
            </Form.Field>
            {/* <Form.Field>
        <div className="ui checkbox">
        <input type="checkbox" onClick={handleCheckbox} name="checkbox" id="checkbox"/>
        
        <label htmlFor="checkbox"><b>Ich bin mit der Speicherung meiner Daten zum Zwecke der Angebotserhebung und Kommunikation einverstanden.<br />
            Mir ist bekannt, dass meine Daten nach 30 Tagen gelöscht werden, falls kein Auftrag zustande kommt.</b>
        </label>
        </div>
        </Form.Field> */}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={() => setOpen(false)}>
          Abbrechen
        </Button>

        <Button
          content="Konfiguration speichern"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            sendRequest();
            setOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
export default Requestform;
