import React from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {updateSettings, getSettings} from "./graphql";

function convertTimestamp(dateTime){
    
const dateObject = new Date(dateTime); // our Date object
return dateObject.toLocaleDateString() + " - " + dateObject.toLocaleTimeString()
}

const currentConfig = {
   
        "konfiguration": {
          "data": {
            "id": "0",
            "attributes": {
              "Tischbreite": 1400,
              "Tischtiefe": 230,
              "Beckenbreite": 600,
              "Beckentiefe": 400,
              "Beckenposition": 600,
              "PreisMwst": 2596.03,
              "UUID": "41a9eadb-049d-4f95-9970-6d4abdc08d52",
              "Valid": "2023-02-22",
              "Bohrung": false,
              "Einzelbecken": true,
              "Untertischablage": false,
              "Anmerkung": "dasdasdas",
              "Abflusstyp": "Punkt",
              "Vorschau": {
                "data": null
              },
              "Kunde": {
                "Vorname": "Marco",
                "Nachname": "Gaib",
                "Strasse": "Klarastraße 68",
                "PLZ": "46537",
                "Ort": "Dinslaken",
                "Telefon": "02064-12345",
                "Mobil": "016095476678",
                "Email": "marco.gaib37@gmail.com",
                "Firmenname": null
              },
              "Material": {
                "Name": "Estana Silver",
                "Preis": 1200,
                "Poliert": false,
                "id": "13",
                "Bild": {
                  "data": {
                    attributes: {
                        formats:{
                            thumbnail:{
                                url: null
                            }
                        }
                    }
                  }
                }
              },
              "users_permissions_user": {
                "data": {
                  "id": "1",
                  "attributes": {
                    "Vorname": "Marco",
                    "Nachname": "Gaib",
                    "username": "Demouser",
                    "filiale": {
                      "data": {
                        "attributes": {
                          "Name": "Dinslaken"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      
    
}

function calcArea(tbreite, ttiefe, thoehe, bbreite, btiefe, bhoehe, doppel){
    let atop = 0
    let afront = 0
    let asides = 0
    let btop = 0
    let bfronts = 0
    let bsides = 0
    let area = 0
    if(doppel){
        atop = tbreite * ttiefe
        afront = tbreite * thoehe
        asides = (ttiefe * thoehe) * 2
        btop = (bbreite * btiefe) * 2
        bfronts = ((bbreite * bhoehe) * 2) * 2
        bsides = ((btiefe * bhoehe) * 2) * 2
    }else{
        atop = tbreite * ttiefe
        afront = tbreite * thoehe
        asides = (ttiefe * thoehe) * 2
        btop = bbreite * btiefe
        bfronts = (bbreite * bhoehe) * 2
        bsides = (btiefe * bhoehe) * 2
        
    }
    area = atop + afront + asides + btop + bfronts + bsides
    return area * 0.000001
}

async function fetchImage(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Fehler beim Herunterladen des Bilds: ${response.statusText}`);
    }
    return await response.blob();
  } catch (error) {
    console.error(error);
    return null;
  }
}

function SaveSettings(settings){
  const [updateSet, { loading, error }] = useMutation(updateSettings);
  updateSet({variables: settings}, {refetchQueries: [{query: getSettings}]})
}
//This function is passed the object containing image formats. 
//The function then searches for the largest available image format 
//and returns the corresponding URL.
function getBiggestImage(formats){
  if(formats.large){
    return formats.large.url
  }
  if(formats.medium){
    return formats.medium.url
  }
  if(formats.small){
    return formats.small.url
  }
}

function map (value) {
  return value / 255.0;
}

export {
    convertTimestamp,
    currentConfig,
    calcArea,
    fetchImage,
    SaveSettings,
    getBiggestImage,
    map
}