import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { getFilialen, deleteFilale } from "../../graphql";
import {
  Table,
  Header,
  Label,
  Button,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";
import FilialenForm from "./update_filiale_form";
import QuestionIcon from "./questionicon";
export default function FilialTable() {
  const { loading, data, error, refetch } = useQuery(getFilialen);
  const [open, setOpen] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [id, setId] = React.useState(0);
  const form = React.useRef(null);
  const openModal = (id) => {
    setId(id);
    setOpen(true);
  };

  const [deleteFilialeMutation] = useMutation(deleteFilale, {
    refetchQueries: [{ query: getFilialen }],
  });

  function handleDelete(id) {
    deleteFilialeMutation({
      variables: {
        ID: id,
      },
    });
  }

  var Heading = () => {
    return (
      <Header>
        <span>
          <Popup
            position="right center"
            content="Neue Filiale anlegen"
            trigger={
              <Button
                icon="plus"
                color="blue"
                onClick={() => {
                  setIsNew(true);
                  setOpen(true);
                }}
              />
            }
          />
        </span>{" "}
      </Header>
    );
  };
  if (data) {
    Heading = () => {
      return (
        <Header>
          <span>
            <Popup
              position="right center"
              content="Neue Filiale anlegen"
              trigger={
                <Button
                  icon="plus"
                  color="blue"
                  onClick={() => {
                    setIsNew(true);
                    setOpen(true);
                  }}
                />
              }
            />
            Filialen
          </span>{" "}
          <Label color="green" circular>
            {data.filialen.data.length}
          </Label>
        </Header>
      );
    };
    return (
      <div>
        <Heading />
        <div className="viewtable">
        <Table striped color="green">
          <Table.Header>
            <Table.Row>
              <Table.Cell className="cellheader">Filiale</Table.Cell>
              <Table.Cell className="cellheader">Adresse</Table.Cell>
              <Table.Cell className="cellheader">Mitarbeiter</Table.Cell>
              <Table.Cell className="cellheader cellleftborder">Aktionen</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.filialen.data.map((conf) => (
              <Table.Row key={conf.id}>
                <Table.Cell>{conf.attributes.Name}</Table.Cell>
                <Table.Cell>
                  {conf.attributes.Strasse} - {conf.attributes.PLZ}{" "}
                  {conf.attributes.Ort}
                </Table.Cell>
                <Table.Cell>
                  <Label color="green" circular size="mini">
                    {conf.attributes.users.data.length}
                  </Label>
                  <br />
                  <br />

                  {conf.attributes.users.data.map((coworker) => (
                    <p key={coworker.id} className="coworkerlist">
                      <small>
                        {coworker.attributes.Vorname}{" "}
                        {coworker.attributes.Nachname}{" "}
                        {coworker.attributes.Telefon && (
                          <span>({coworker.attributes.Telefon})</span>
                        )}
                      </small>
                    </p>
                  ))}
                </Table.Cell>
                <Table.Cell className="cellleftborder">
                  <Button
                    color="blue"
                    icon
                    size="tiny"
                    onClick={() => {
                      setIsNew(false);
                      openModal(conf.id);
                    }}
                  >
                    <Icon name="edit" />
                  </Button>
                  <Button
                    color="red"
                    icon
                    size="tiny"
                    onClick={() => handleDelete(conf.id)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        </div>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {isNew ? (
            <Modal.Header>Filiale hinzufügen</Modal.Header>
          ) : (
            <Modal.Header>Filiale aktualisieren</Modal.Header>
          )}  
          <Modal.Content>
            <Modal.Description>
              <FilialenForm
                id={id}
                ref={form}
                refetch={getFilialen}
                new={isNew}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            <Button
              content="Speichern"
              onClick={() => {
                form.current.handleSubmit();
                setOpen(false);
              }}
              color="blue"
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <Heading />
        Suche Filialen...
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <Heading />
        Keine Filialen gefunden!
      </div>
    );
  }
}
