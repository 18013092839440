import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import Cookies from "js-cookie";
import Config from "./Config";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, HttpLink } from "@apollo/client";
import { useQuery } from "@apollo/client";
var uploadLink;
if (Cookies.get(Config.TokenName)) {
  uploadLink = createUploadLink({
    uri: Config.APIURL + "/graphql",
    credentials: "include",
    mode: "cors",
    fetch,
    fetchOptions: { credentials: "include" },
    headers: { authorization: "Bearer " + Cookies.get(Config.TokenName) },
  });
} else {
  uploadLink = createUploadLink({
    uri: Config.APIURL + "/graphql",
    credentials: "include",
    mode: "cors",
    fetch,
    fetchOptions: { credentials: "include" },
  });
}

//const httpLink = new HttpLink({ uri: Config.APIURL + "/graphql" });
const client = new ApolloClient({
  link: ApolloLink.from([uploadLink]),
  cache: new InMemoryCache(),
});

const getLogo = gql`
  query getLogo {
    einstellungen {
      data {
        attributes {
          Titel
          Logo {
            data {
              id
              attributes {
                formats
                url
              }
            }
          }
        }
      }
    }
  }
`;

const getMaterials = gql`
  query getMaterials {
    materials(pagination: { page: 1, pageSize: 100 }) {
      data {
        id
        attributes {
          Name
          Poliert
          Preis
          Bild {
            data {
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;

const getMaterial = gql`
  query getMaterial($ID: ID) {
    material(id: $ID) {
      data {
        id
        attributes {
          Name
          Beschreibung
          Bild {
            data {
              id
              attributes {
                formats
              }
            }
          }
          Preis
          Poliert
        }
      }
    }
  }
`;

const deleteMaterial = gql`
  mutation deleteMaterial($ID: ID!) {
    deleteMaterial(id: $ID) {
      data {
        id
      }
    }
  }
`;

const updateMaterial = gql`
  mutation updateMaterial(
    $ID: ID!
    $Beschreibung: String
    $Name: String
    $Poliert: Boolean
    $Preis: Float
    $Bild: ID
  ) {
    updateMaterial(
      id: $ID
      data: {
        Beschreibung: $Beschreibung
        Name: $Name
        Poliert: $Poliert
        Preis: $Preis
        Bild: $Bild
      }
    ) {
      data {
        id
        attributes {
          Beschreibung
        }
      }
    }
  }
`;

const createMaterial = gql`
  mutation createMaterial(
    $Beschreibung: String
    $Name: String
    $Poliert: Boolean
    $Preis: Float
    $Bild: ID
  ) {
    createMaterial(
      data: {
        Beschreibung: $Beschreibung
        Name: $Name
        Poliert: $Poliert
        Preis: $Preis
        Bild: $Bild
      }
    ) {
      data {
        id
        attributes {
          Beschreibung
        }
      }
    }
  }
`;

const createConfiguration = gql`
  mutation createConfiguration(
    $Tischbreite: Int!
    $Tischtiefe: Int!
    $Beckenbreite: Int!
    $Beckentiefe: Int!
    $Beckenposition: Int!
    $PreisMwst: Float!
    $UUID: String!
    $Valid: Date!
    $Bohrung: Boolean!
    $Einzelbecken: Boolean!
    $Abflusstyp: ENUM_KONFIGURATION_ABFLUSSTYP!
    $Untertischablage: Boolean!
    $Anmerkung: String!
    $KFirmenname: String
    $KVorname: String!
    $KNachname: String!
    $KStrasse: String
    $KPLZ: String
    $KOrt: String
    $KTelefon: String
    $KMobil: String
    $KEmail: String!
    $MName: String!
    $MPoliert: Boolean!
    $MPreis: Float!
    $UserID: ID!
    $Materialpreis: Float!
    $Produktionspreis: Float!
    $Bild: ID
    $Mid: Long
  ) {
    createKonfiguration(
      data: {
        Tischbreite: $Tischbreite
        Tischtiefe: $Tischtiefe
        Beckenbreite: $Beckenbreite
        Beckentiefe: $Beckentiefe
        Beckenposition: $Beckenposition
        PreisMwst: $PreisMwst
        UUID: $UUID
        Valid: $Valid
        Bohrung: $Bohrung
        Einzelbecken: $Einzelbecken
        Abflusstyp: $Abflusstyp
        Untertischablage: $Untertischablage
        Anmerkung: $Anmerkung
        Materialpreis: $Materialpreis
        Produktionspreis: $Produktionspreis
        Kunde: {
          Vorname: $KVorname
          Nachname: $KNachname
          Strasse: $KStrasse
          PLZ: $KPLZ
          Ort: $KOrt
          Telefon: $KTelefon
          Mobil: $KMobil
          Email: $KEmail
          Firmenname: $KFirmenname
        }
        Material: {
          Name: $MName
          Preis: $MPreis
          Poliert: $MPoliert
          Bild: $Bild
          matid: $Mid
        }
        users_permissions_user: $UserID
      }
    ) {
      data {
        id
      }
    }
  }
`;

const updateConfiguration = gql`
  mutation createConfiguration(
    $ID: ID!
    $Tischbreite: Int
    $Tischtiefe: Int
    $Beckenbreite: Int
    $Beckentiefe: Int
    $Beckenposition: Int
    $PreisMwst: Float
    $Bohrung: Boolean
    $Einzelbecken: Boolean
    $Abflusstyp: ENUM_KONFIGURATION_ABFLUSSTYP
    $Untertischablage: Boolean
    $MName: String
    $MPoliert: Boolean
    $MPreis: Float
    $Materialpreis: Float
    $Produktionspreis: Float
    $Bild: ID
    $Mid: Long
  ) {
    updateKonfiguration(
      id: $ID
      data: {
        Tischbreite: $Tischbreite
        Tischtiefe: $Tischtiefe
        Beckenbreite: $Beckenbreite
        Beckentiefe: $Beckentiefe
        Beckenposition: $Beckenposition
        PreisMwst: $PreisMwst
        Bohrung: $Bohrung
        Einzelbecken: $Einzelbecken
        Abflusstyp: $Abflusstyp
        Untertischablage: $Untertischablage
        Materialpreis: $Materialpreis
        Produktionspreis: $Produktionspreis
        Material: {
          Name: $MName
          Preis: $MPreis
          Poliert: $MPoliert
          Bild: $Bild
          matid: $Mid
        }
      }
    ) {
      data {
        id
      }
    }
  }
`;

const deleteConfiguration = gql`
  mutation deleteConfiguration($ID: ID!) {
    deleteKonfiguration(id: $ID) {
      data {
        __typename
      }
    }
  }
`;

const UserLogin = gql`
  mutation Login($Identifier: String!, $Password: String!) {
    login(input: { identifier: $Identifier, password: $Password }) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`;

const getMe = gql`
  query {
    me {
      Mobil
      id
      username
      email
      Telefon
      Filiale {
        __typename
        data {
          id
          attributes {
            Name
          }
        }
      }
      role {
        id
        name
        description
      }
    }
  }
`;

const getUserConfigurationsByUsername = gql`
  query getUserConfigurationsByUsername($Username: String!) {
    konfigurationen(
      filters: { users_permissions_user: { username: { eq: $Username } } }
    ) {
      data {
        id
        attributes {
          Tischbreite
          Tischtiefe
          Beckenbreite
          Beckentiefe
          Beckenposition
          PreisMwst
          createdAt
          UUID
          Valid
          Bohrung
          Einzelbecken
          Untertischablage
          Anmerkung
          Abflusstyp
          Vorschau {
            data {
              id
              attributes {
                formats
              }
            }
          }
          Kunde {
            Vorname
            Nachname
            Strasse
            PLZ
            Ort
            Telefon
            Mobil
            Email
            Firmenname
          }
          Material {
            Name
            Preis
            Poliert
            id
            Bild {
              data {
                id
                attributes {
                  formats
                }
              }
            }
          }
          users_permissions_user {
            data {
              id
              attributes {
                Vorname
                Nachname
                username
              }
            }
          }
        }
      }
    }
  }
`;

const orderConfiguration = gql`
  mutation orderConfig($ID: ID!, $Bestellt: Boolean) {
    updateKonfiguration(id: $ID, data: { Bestellt: $Bestellt }) {
      data {
        id
        attributes {
          Bestellt
        }
      }
    }
  }
`;

const getUserConfigurations = gql`
  query getUserConfiguration($Filter: UsersPermissionsUserFiltersInput) {
    konfigurationen(
      pagination: { page: 1, pageSize: 100 }
      filters: { users_permissions_user: $Filter }
    ) {
      data {
        id
        attributes {
          createdAt
          Tischbreite
          Tischtiefe
          Beckenbreite
          Beckentiefe
          Beckenposition
          PreisMwst
          UUID
          Valid
          Bohrung
          Einzelbecken
          Untertischablage
          Anmerkung
          Abflusstyp
          Materialpreis
          Produktionspreis
          Bestellt
          Vorschau {
            data {
              id
              attributes {
                formats
              }
            }
          }
          Kunde {
            Vorname
            Nachname
            Strasse
            PLZ
            Ort
            Telefon
            Mobil
            Email
            Firmenname
          }
          Material {
            Name
            Preis
            Poliert
            id
            matid
            Bild {
              data {
                attributes {
                  formats
                }
              }
            }
          }
          users_permissions_user {
            data {
              id
              attributes {
                Vorname
                Nachname
                username
                filiale {
                  data {
                    id
                    attributes {
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getSingleUserConfiguration = gql`
  query getSingleConfig($ID: ID!) {
    konfiguration(id: $ID) {
      data {
        id
        attributes {
          createdAt
          Tischbreite
          Tischtiefe
          Beckenbreite
          Beckentiefe
          Beckenposition
          PreisMwst
          UUID
          Valid
          Bohrung
          Einzelbecken
          Untertischablage
          Anmerkung
          Abflusstyp
          Materialpreis
          Produktionspreis
          Vorschau {
            data {
              id
              attributes {
                formats
              }
            }
          }
          Kunde {
            Vorname
            Nachname
            Strasse
            PLZ
            Ort
            Telefon
            Mobil
            Email
            Firmenname
          }
          Material {
            Name
            Preis
            Poliert
            id
            Bild {
              data {
                attributes {
                  formats
                }
              }
            }
          }
          users_permissions_user {
            data {
              id
              attributes {
                Vorname
                Nachname
                username
                Telefon
                Mobil
                filiale {
                  data {
                    attributes {
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getFilialen = gql`
  query {
    filialen(pagination: { page: 1, pageSize: 100 }) {
      data {
        id
        attributes {
          Name
          Strasse
          PLZ
          Ort
          users {
            data {
              id
              attributes {
                username
                Vorname
                Nachname
                Telefon
                Mobil
              }
            }
          }
        }
      }
    }
  }
`;

const deleteFilale = gql`
  mutation deleteFiliale($ID: ID!) {
    deleteFiliale(id: $ID) {
      __typename
    }
  }
`;

const getRoles = gql`
  query {
    usersPermissionsRoles {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const getSettings = gql`
  query {
    einstellungen {
      data {
        attributes {
          Logo {
            data {
              attributes {
                formats
              }
            }
          }
          Titel
          Preismultiplikator
          Logoanzeigen
          Konfiguratoranzeigen
          css
          Preisanzeigen
        }
      }
    }
  }
`;

const getUsers = gql`
  query {
    usersPermissionsUsers(pagination: { page: 1, pageSize: 100 }) {
      data {
        id
        attributes {
          username
          Vorname
          Nachname
          role {
            data {
              attributes {
                name
              }
            }
          }
          Telefon
          Mobil
          email
          filiale {
            data {
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

const getUser = gql`
  query getUser($ID: ID) {
    usersPermissionsUser(id: $ID) {
      data {
        id
        attributes {
          username
          Vorname
          Nachname
          email
          blocked
          Telefon
          Mobil
          Preisanzeigen
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const updateUser = gql`
  mutation updateUser(
    $ID: ID!
    $username: String
    $Vorname: String
    $Nachname: String
    $Telefon: String
    $Mobil: String
    $email: String
    $Role: ID
    $Password: String
    $Blocked: Boolean
    $Preisanzeigen: Boolean
  ) {
    updateUsersPermissionsUser(
      id: $ID
      data: {
        username: $username
        Vorname: $Vorname
        Nachname: $Nachname
        Telefon: $Telefon
        Mobil: $Mobil
        email: $email
        role: $Role
        password: $Password
        blocked: $Blocked
        Preisanzeigen: $Preisanzeigen
      }
    ) {
      data {
        id
        attributes {
          username
          Vorname
          Nachname
          email
          role {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const createUser = gql`
  mutation createUser(
    $username: String!
    $Vorname: String
    $Nachname: String
    $Telefon: String
    $Mobil: String
    $email: String!
    $Password: String!
  ) {
    register(
      input: {
        username: $username
        Vorname: $Vorname
        Nachname: $Nachname
        Telefon: $Telefon
        Mobil: $Mobil
        email: $email
        password: $Password
      }
    ) {
      jwt
      user {
        id
      }
    }
  }
`;

const deleteUser = gql`
  mutation deleteUser($ID: ID!) {
    deleteUsersPermissionsUser(id: $ID) {
      __typename
    }
  }
`;

const updateSettings = gql`
  mutation updateSettings(
    $Logoanzeigen: Boolean
    $Titel: String
    $Preismultiplikator: Float
    $Konfiguratoranzeigen: Boolean
    $css: String
    $Preisanzeigen: Boolean
  ) {
    updateEinstellungen(
      data: {
        Logoanzeigen: $Logoanzeigen
        Titel: $Titel
        Preismultiplikator: $Preismultiplikator
        Konfiguratoranzeigen: $Konfiguratoranzeigen
        css: $css
        Preisanzeigen: $Preisanzeigen
      }
    ) {
      data {
        attributes {
          Titel
          Logoanzeigen
          Konfiguratoranzeigen
          Preismultiplikator
        }
      }
    }
  }
`;

const UPLOAD_IMAGE_MUTATION = gql`
  mutation uploadImage($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
`;

const updateFiliale = gql`
  mutation updateFiliale(
    $ID: ID!
    $Name: String!
    $Beschreibung: String
    $Strasse: String!
    $PLZ: String!
    $Ort: String!
    $Users: [ID]
  ) {
    updateFiliale(
      id: $ID
      data: {
        Name: $Name
        Beschreibung: $Beschreibung
        Strasse: $Strasse
        PLZ: $PLZ
        Ort: $Ort
        users: $Users
      }
    ) {
      data {
        id
        attributes {
          Name
          Strasse
          PLZ
          Ort
          Beschreibung
          users {
            data {
              id
              attributes {
                username
                Vorname
                Nachname
              }
            }
          }
        }
      }
    }
  }
`;

const createFiliale = gql`
  mutation createFiliale(
    $Name: String!
    $Beschreibung: String
    $Strasse: String!
    $PLZ: String!
    $Ort: String!
    $Users: [ID]
  ) {
    createFiliale(
      data: {
        Name: $Name
        Beschreibung: $Beschreibung
        Strasse: $Strasse
        PLZ: $PLZ
        Ort: $Ort
        users: $Users
      }
    ) {
      data {
        id
        attributes {
          Name
          Strasse
          PLZ
          Ort
          Beschreibung
          users {
            data {
              id
              attributes {
                username
                Vorname
                Nachname
              }
            }
          }
        }
      }
    }
  }
`;

function GetSettings() {
  const { loading, error, data } = useQuery(getSettings);

  return data;
}

export {
  client,
  getMaterials,
  getMaterial,
  createMaterial,
  updateMaterial,
  deleteMaterial,
  getLogo,
  getSettings,
  updateSettings,
  createConfiguration,
  updateConfiguration,
  deleteConfiguration,
  UserLogin,
  getMe,
  getRoles,
  getUserConfigurationsByUsername,
  getUserConfigurations,
  orderConfiguration,
  getFilialen,
  deleteFilale,
  getSingleUserConfiguration,
  UPLOAD_IMAGE_MUTATION,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  createUser,
  updateFiliale,
  createFiliale,
  GetSettings,
};
