/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useRef, useState } from "react";
import { Tab, Form, Popup } from "semantic-ui-react";
import SinkConfig from "./SinkConfig";
import { useEffect } from "react";
import { SCENE } from "./Scene";
import Config from "../Config";
import Cookies from "js-cookie";
import { getBiggestImage } from "../helpers";
import { useQuery } from "@apollo/client";
import { getMaterials, updateMaterial } from "../graphql";
const Tabui = (props) => {
  const [faucetState, setFaucetValue] = useState(1);
  const [singleTypeState, setSingleType] = useState(1);
  const [sinkTypeState, setSinkType] = useState(1);
  //const [materials, setmaterials] = useState([]);
  const [activeMaterial, setActiveMaterial] = useState(SinkConfig.materialid);
  const [options, setOptions] = useState([]);
  const [underTable, setUnderTable] = useState(false);
  useEffect(() => {
    //getOpenOrders();
    //setmaterials(props.materials)
    console.log("USEEFFECT");
    if (localStorage.getItem("loadConfig") != null) {
      if (localStorage.getItem("loadConfig") === "true") {
        if (Cookies.get(Config.TokenName)) {
          if (localStorage.getItem("SinkConfig") != null) {
            /* let conf = JSON.parse(localStorage.getItem("SinkConfig"));
            for (var val in SinkConfig) SinkConfig[val] = conf[val]; */
            if (SinkConfig.withUnderTable === true) {
              SCENE.showUnderTable = true;
            } else {
              SCENE.showUnderTable = false;
            }
          }
        }
      }
    }
    /* if (localStorage.getItem("SinkConfig") != null) {
      console.log("Updating settings from localstorage")
      let conf = JSON.parse(localStorage.getItem("SinkConfig"));
      //for (var val in SinkConfig) SinkConfig[val] = conf[val];
      Object.assign(SinkConfig,conf)
    } */
    CheckSinkWidthOptions();

    console.log("Seite fertig geladen");
    if (localStorage.getItem("SinkConfig") != null) {
      console.log("LOADING SETTINGS FROM LOCALSTORAGE");
      let conf = JSON.parse(localStorage.getItem("SinkConfig"));

      Object.assign(SinkConfig, conf);

      console.log(
        "EQUAL",
        JSON.stringify(SinkConfig) === localStorage.getItem("SinkConfig")
      );

      console.log("SinkConfig-geladen", SinkConfig);
      refInpTableWidth.current.value = SinkConfig.tableWidth;
      refInpTableHeight.current.value = SinkConfig.tableHeight;
      refSelectSinkWidth.current.value = SinkConfig.sinkWidth;
      refInpSinkPos.current.value = SinkConfig.sinkPos;
      if (SinkConfig.sinkMontage) {
        console.log("Montage:", 1);
        setFaucetValue(1);
      } else {
        console.log("Montage:", 0);
        setFaucetValue(0);
      }
      if (SinkConfig.singleSink) {
        console.log("Montage:", 1);
        setSingleType(1);
      } else {
        console.log("Montage:", 0);
        setSingleType(0);
      }
      setSinkType(SinkConfig.sinkType);
      setUnderTable(SinkConfig.withUnderTable);
      setActiveMaterial(SinkConfig.materialid);
      SCENE.showUnderTable = SinkConfig.withUnderTable;
      localStorage.setItem("isloaded", "true")
    }
    
    //return localStorage.setItem("loadConfig", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("SinkConfig::", SinkConfig.tableWidth);
  console.log("SinkConfigHeight::", SinkConfig.tableHeight);
  /*  React.useEffect(() => {
    console.log("USEEFFECT")
    if (localStorage.getItem("loadConfig") != null) {
      if (localStorage.getItem("loadConfig") === "true") {
        setUnderTable(SinkConfig.withUnderTable);
        setSinkType(SinkConfig.sinkType);
        if (SinkConfig.singleSink) {
          setSingleType(1);
        } else {
          setSingleType(0);
        }
        if (SinkConfig.sinkMontage) {
          setFaucetValue(1);
        } else {
          setFaucetValue(0);
        }
        setActiveMaterial(SinkConfig.materialid);
        //localStorage.setItem("loadConfig", false);
        SCENE.update = true;
      }
    }

    
  }, [SinkConfig.withUnderTable, SinkConfig.sinkType, SinkConfig.singleSink]); */

  const { loading, error, data } = useQuery(getMaterials, {
    onCompleted: (data) => {
      let matid = 4;
      if (data.materials.data[4]) {
        matid = 4;
      } else {
        matid = 0;
      }

      changeMaterial(
        data.materials.data[matid].id,
        getBiggestImage(
          data.materials.data[matid].attributes.Bild.data.attributes.formats
        ),
        data.materials.data[matid].attributes.Name,
        data.materials.data[matid].attributes.Poliert,
        data.materials.data[matid].attributes.Bild.data.attributes.formats
          .thumbnail.url,
        data.materials.data[matid].attributes.Preis
      );
    },
  });

  //Reference for SinkPosition Input
  const refInpSinkPos = useRef(null);
  //Reference for Label of the SinkPosition
  const refLblSinkPos = useRef("0-0 mm");
  const reflblSinkWidth = useRef("0-0 mm");
  const refInpTableWidth = useRef(null);
  const refInpTableHeight = useRef(null);
  const refLblTableWidth = useRef(null);
  const refLblTableHeight = useRef(null);
  const refSelectSinkWidth = useRef(null);

  //const testdat = [{"name":"demo"}]
  var optioninner = "";

  function handleFaucetChange(val) {
    setFaucetValue(val);
    SinkConfig.sinkMontage = val;

    SCENE.update = true;
  }

  function handleSingleTypeChange(val) {
    setSingleType(val);
    SinkConfig.singleSink = val;

    CheckUi();
  }

  function handleSinkTypeChange(val) {
    setSinkType(val);
    SinkConfig.sinkType = val;

    SCENE.update = true;
  }

  function handleUnderTableChange(val) {
    console.log("Changing undertable");
    setUnderTable(val.target.checked);
    SinkConfig.withUnderTable = val.target.checked;

    SCENE.showUnderTable = val.target.checked;
    SCENE.update = true;
  }

  function handleUiUpdate(e) {
    if (e.key === "Enter" || e.key === "Tab") {
      CheckUi();
    }
  }

  function handleSelectUpdate(e) {
    CheckUi();
  }

  function handleFocusTableWidth() {
    console.log("tablewidth")
    SCENE.showTableWidthLines = true;
    SCENE.showTableHeightLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.update = true;
    refInpTableWidth.current.select();
  }

  function handleFocusTableHeight() {
    console.log("tableheight")
    SCENE.showTableHeightLines = true;
    SCENE.showTableWidthLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.update = true;
    refInpTableHeight.current.select();
  }

  function handleFocusSinkWidth() {
    SCENE.showSinkWidthLines = true;
    SCENE.showTableHeightLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showTableWidthLines = false;
    SCENE.update = true;
  }

  function handleFocusSinkPosition() {
    SCENE.showSinkPositionLines = true;
    SCENE.showTableHeightLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.showTableWidthLines = false;
    SCENE.update = true;
    refInpSinkPos.current.select();
  }

  function handleBlurTableWidth() {
    SCENE.showTableWidthLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.showTableHeightLines = false;
    SCENE.update = true;
  }

  function handleBlurTableHeight() {
    SCENE.showTableHeightLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.showTableWidthLines = false;
    SCENE.update = true;
  }

  function handleBlurSinkWidth() {
    SCENE.showSinkWidthLines = false;
    SCENE.showSinkPositionLines = false;
    SCENE.showTableWidthLines = false;
    SCENE.showTableHeightLines = false;
    SCENE.update = true;
  }

  function handleBlurSinkPosition() {
    SCENE.showSinkPositionLines = false;
    SCENE.showSinkWidthLines = false;
    SCENE.showTableWidthLines = false;
    SCENE.showTableHeightLines = false;
    SCENE.update = true;
  }

  /* function showPreview(img){

    props.setImgURL(img)
  } */

  //Ui Definitions for the "Dimensionen"-Tab
  const tabDimensionen = (
    <div>
      <h4>Waschtischbreite:</h4>

      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <input
              type="number"
              placeholder="Waschtischbreite..."
              defaultValue={SinkConfig.tableWidth}
              id="inpTableWidth"
              onKeyDown={handleUiUpdate}
              ref={refInpTableWidth}
              onFocus={handleFocusTableWidth}
              onBlur={handleBlurTableWidth}
            />
            <div
              className="ui basic label label"
              id="lblTableWidth"
              ref={refLblTableWidth}
            >
              {SinkConfig.minTableWidth}-{SinkConfig.maxTableWidth} mm
            </div>
          </div>
        </div>
        <div className="six wide column">
          {/* <img
            src="wbreite.png"
            alt=""
            style={{ marginLeft: "40px" }}
            className="ui right floated image descimage"
          /> */}
        </div>
      </div>
      <h4>Waschtischhöhe:</h4>

      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <input
              type="number"
              placeholder="Waschtischhöhe..."
              defaultValue={SinkConfig.tableHeight}
              id="inpTableHeight"
              onKeyDown={handleUiUpdate}
              ref={refInpTableHeight}
              onFocus={handleFocusTableHeight}
              onBlur={handleBlurTableHeight}
            />
            <div
              className="ui basic label label"
              id="lblTableHeight"
              ref={refLblTableHeight}
            >
              {SinkConfig.minTableHeight}-{SinkConfig.maxTableHeight} mm
            </div>
          </div>
        </div>
        <div className="six wide column">
          {/* <img
            src="wbreite.png"
            alt=""
            style={{ marginLeft: "40px" }}
            className="ui right floated image descimage"
          /> */}
        </div>
      </div>
      <h4>Beckenbreite:</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <select
              label=""
              options={options}
              placeholder={SinkConfig.sinkWidth}
              ref={refSelectSinkWidth}
              onChange={handleSelectUpdate}
              className="ui selection dropdown"
              onFocus={handleFocusSinkWidth}
              onBlur={handleBlurSinkWidth}
            >
              <option value="400">400 mm</option>
              <option value="600" selected="selected">
                600 mm
              </option>
            </select>

            <div
              className="ui basic label label"
              id="lblTableWidth"
              ref={reflblSinkWidth}
            >
              400-1000 mm
            </div>
          </div>
          <br />
          {/* TODO: Grid Layout for images */}
        </div>

        <div className="six wide column">
          {/* <img
            src="bbreite.png"
            alt=""
            style={{
              marginLeft: "40px",
            }}
            className="ui right floated image descimage"
          /> */}
        </div>
      </div>
      <h4>Beckenposition:</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <input
              type="number"
              placeholder="Enter weight..."
              defaultValue={SinkConfig.sinkPos}
              id="inpSinkPos"
              onKeyDown={handleUiUpdate}
              ref={refInpSinkPos}
              onFocus={handleFocusSinkPosition}
              onBlur={handleBlurSinkPosition}
            />
            <div
              className="ui basic label label"
              id="lblTableWidth"
              ref={refLblSinkPos}
            >
              400-800 mm
            </div>
          </div>
        </div>
        <div className="six wide column">
          {/* <img
            src="bpos.png"
            alt=""
            style={{
              verticalAlign: "middle",
              paddingLeft: "5px",
              marginTop: "5px",
            }}
            className="ui right floated image descimage"
          /> */}
        </div>
      </div>
    </div>
  );

  //Ui Definitions for the "Ausstattung"-Tab
  const tabAusstattung = (
    <div>
      <h4>Wasserhahn:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Beckenmontage"
            value="1"
            checked={faucetState === 1}
            onChange={() => handleFaucetChange(1)}
          />

          <Form.Radio
            label="Wandmontage"
            value="0"
            checked={faucetState === 0}
            onChange={() => handleFaucetChange(0)}
          />
        </Form.Group>
      </div>
      <h4>Waschbeckentyp:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Einzelbecken"
            value="1"
            checked={singleTypeState === 1}
            onChange={() => handleSingleTypeChange(1)}
          />

          <Form.Radio
            label="Doppelbecken"
            value="0"
            checked={singleTypeState === 0}
            onChange={() => handleSingleTypeChange(0)}
          />
        </Form.Group>
      </div>
      <h4>Abflusstyp:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Punkt"
            value="0"
            checked={sinkTypeState === 0}
            onChange={() => handleSinkTypeChange(0)}
          />

          <Form.Radio
            label="Rinne"
            value="1"
            checked={sinkTypeState === 1}
            onChange={() => handleSinkTypeChange(1)}
          />

          <Form.Radio
            label="Schlitz"
            value="2"
            checked={sinkTypeState === 2}
            onChange={() => handleSinkTypeChange(2)}
          />
        </Form.Group>
      </div>

      <h4>Untertischablage:</h4>

      <div className="ui form">
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="a"
            id="a"
            checked={underTable}
            onChange={handleUnderTableChange}
          />
          <label>
            <i className="b"></i> Wünschen Sie zu ihrem neuen Waschtisch gleich
            die passende Untertischablage?
          </label>
        </div>
      </div>
    </div>
  );

  const tabMaterials = (
    <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
      <table className="ui celled table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Glänzend</th>
            <th>Vorschau</th>
          </tr>
        </thead>
        <tbody>
          {props.materials.map((material) =>
            material.id === activeMaterial ? (
              <tr className="active" key={material.id}>
                <td>
                  <a
                    className="selectmaterial"
                    href="#"
                    onClick={() =>
                      changeMaterial(
                        material.id,
                        getBiggestImage(
                          material.attributes.Bild.data.attributes.formats
                        ),
                        material.attributes.Name,
                        material.attributes.Poliert,
                        material.attributes.Bild.data.attributes.formats
                          .thumbnail.url,
                        material.attributes.Preis
                      )
                    }
                    key={material.id}
                  >
                    {material.attributes.Name}
                  </a>
                </td>
                <td>
                  {material.attributes.Poliert ? <b>poliert</b> : <b>matt</b>}
                </td>
                <td>
                  <Popup
                    trigger={
                      <a
                        href="#"
                        onClick={() =>
                          changeMaterial(
                            material.id,
                            material.attributes.Bild.data.attributes.formats
                              .large.url,
                            material.attributes.Name,
                            material.attributes.Poliert,
                            material.attributes.Bild.data.attributes.formats
                              .thumbnail.url,
                            material.attributes.Preis
                          )
                        }
                      >
                        <img
                          src={
                            Config.APIURL +
                            material.attributes.Bild.data.attributes.formats
                              .thumbnail.url
                          }
                          alt={material.attributes.Name}
                        ></img>
                      </a>
                    }
                    position="right center"
                  >
                    <Popup.Content>
                      <img
                        src={
                          Config.APIURL +
                          getBiggestImage(
                            material.attributes.Bild.data.attributes.formats
                          )
                        }
                        alt={material.attributes.Name}
                        style={{ height: "80vh" }}
                      ></img>
                    </Popup.Content>
                  </Popup>
                </td>
              </tr>
            ) : (
              <tr key={material.id}>
                <td>
                  <a
                    href="#"
                    onClick={() =>
                      changeMaterial(
                        material.id,
                        getBiggestImage(
                          material.attributes.Bild.data.attributes.formats
                        ),
                        material.attributes.Name,
                        material.attributes.Poliert,
                        material.attributes.Bild.data.attributes.formats
                          .thumbnail.url,
                        material.attributes.Preis
                      )
                    }
                  >
                    {material.attributes.Name}
                  </a>
                </td>
                <td>
                  {material.attributes.Poliert ? <b>poliert</b> : <b>matt</b>}
                </td>
                <td>
                  <Popup
                    trigger={
                      <a
                        href="#"
                        onClick={() =>
                          changeMaterial(
                            material.id,
                            getBiggestImage(
                              material.attributes.Bild.data.attributes.formats
                            ),
                            material.attributes.Name,
                            material.attributes.Poliert,
                            material.attributes.Bild.data.attributes.formats
                              .thumbnail.url,
                            material.attributes.Preis
                          )
                        }
                      >
                        <img
                          src={
                            Config.APIURL +
                            material.attributes.Bild.data.attributes.formats
                              .thumbnail.url
                          }
                          alt={material.attributes.Name}
                        ></img>
                      </a>
                    }
                    position="right center"
                  >
                    <Popup.Content>
                      <img
                        src={
                          Config.APIURL +
                          getBiggestImage(
                            material.attributes.Bild.data.attributes.formats
                          )
                        }
                        alt={material.attributes.Name}
                        style={{ height: "80vh" }}
                      ></img>
                    </Popup.Content>
                  </Popup>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );

  //Change Material
  function changeMaterial(id, tex, name, gloss, thumb, preis) {
    SinkConfig.materialid = id;
    SinkConfig.materialimage = Config.APIURL + tex;
    SinkConfig.materialname = name;
    SinkConfig.materialglossy = gloss;
    SinkConfig.thumbnail = Config.APIURL + thumb;
    SinkConfig.materialprice = preis;

    setActiveMaterial(id);

    SCENE.update = true;
  }
  //Check the given SinkPosition for Validity

  //Check the Value Range of the sinkWidth Options
  function CheckSinkWidthOptions() {
    refSelectSinkWidth.current.value = SinkConfig.sinkWidth;

    let opt = [];
    //Check SinkSize
    let maxSinkWidth = 0;
    if (SinkConfig.singleSink) {
      maxSinkWidth = SinkConfig.tableWidth - SinkConfig.outerBorder * 2;
    } else {
      maxSinkWidth = SinkConfig.tableWidth / 2 - SinkConfig.innerBorder;
    }
    if (refSelectSinkWidth.current.value > maxSinkWidth) {
      let ret = 0;
      for (let i = 0; i < SinkConfig.sinkSteps.length; i++) {
        if (SinkConfig.sinkSteps[i] <= maxSinkWidth) {
          ret = i;
        }
      }
      refSelectSinkWidth.current.value = ret;
      SinkConfig.sinkWidth = ret;
    }
    if (refSelectSinkWidth.current.value < SinkConfig.minSinkWidth) {
      refSelectSinkWidth.current.value = SinkConfig.sinkSteps[0];
      SinkConfig.sinkWidth = SinkConfig.sinkSteps[0];
    }

    for (let i = 0; i < SinkConfig.sinkSteps.length; i++) {
      if (SinkConfig.sinkSteps[i] <= maxSinkWidth) {
        //opt.push({ key: SinkConfig.sinkSteps[i], text: SinkConfig.sinkSteps[i] + ' mm', value: SinkConfig.sinkSteps[i] })
        if (SinkConfig.sinkSteps[i] !== SinkConfig.sinkWidth) {
          optioninner +=
            "<option value='" +
            SinkConfig.sinkSteps[i] +
            "'>" +
            SinkConfig.sinkSteps[i] +
            " mm</option>";
        } else {
          optioninner +=
            "<option value='" +
            SinkConfig.sinkSteps[i] +
            "' selected='selected'>" +
            SinkConfig.sinkSteps[i] +
            " mm</option>";
        }
      } else {
      }
    }

    refSelectSinkWidth.current.innerHTML = optioninner;
    refSelectSinkWidth.current.value = SinkConfig.sinkWidth;

    setOptions(opt);
  }

  //Test for new Checkui
  function CheckUi() {
    let maxSinkPosition = 0;
    var minTableWidth = SinkConfig.minTableWidth;
    if (refInpTableHeight.current.value < SinkConfig.minTableHeight){

      refInpTableHeight.current.value = SinkConfig.minTableHeight;
      
    }
    if (refInpTableHeight.current.value > SinkConfig.maxTableHeight){
      refInpTableHeight.current.value = SinkConfig.maxTableHeight;
      
    }
    SinkConfig.tableHeight = refInpTableHeight.current.value;
    //Check Tablewidth
    console.log("Set TableWidth to: " + refInpTableWidth.current.value + " mm");
    SinkConfig.tableWidth = refInpTableWidth.current.value;
    console.log("Tablewidth: " + SinkConfig.tableWidth + " mm");
    if (SinkConfig.singleSink) {
      minTableWidth = SinkConfig.minTableWidth;
    } else {
      minTableWidth =
        SinkConfig.minTableWidth +
        SinkConfig.innerBorder +
        SinkConfig.minSinkWidth +
        SinkConfig.outerBorder;
    }

    if (refInpTableWidth.current.value > SinkConfig.maxTableWidth) {
      refInpTableWidth.current.value = SinkConfig.maxTableWidth;
      console.log(
        "Set TableWidth to: " + refInpTableWidth.current.value + " mm"
      );
      SinkConfig.tableWidth = refInpTableWidth.current.value;
    }
    if (refInpTableWidth.current.value < minTableWidth) {
      refInpTableWidth.current.value = minTableWidth;
      console.log(
        "Set TableWidth to: " + refInpTableWidth.current.value + " mm"
      );
      SinkConfig.tableWidth = refInpTableWidth.current.value;
    }

    //Check SinkSize

    var maxSinkWidth = 0;
    if (SinkConfig.singleSink) {
      if (
        SinkConfig.tableWidth - SinkConfig.outerBorder * 2 >
        SinkConfig.maxSinkWidth
      ) {
        maxSinkWidth = SinkConfig.maxSinkWidth;
      } else {
        maxSinkWidth = SinkConfig.tableWidth - SinkConfig.outerBorder * 2;
      }
    } else {
      if (
        SinkConfig.tableWidth / 2 -
          SinkConfig.outerBorder -
          SinkConfig.innerBorder >
        SinkConfig.maxSinkWidth
      ) {
        maxSinkWidth = SinkConfig.maxSinkWidth;
      } else {
        maxSinkWidth =
          SinkConfig.tableWidth / 2 -
          SinkConfig.outerBorder -
          SinkConfig.innerBorder;
      }
    }
    SinkConfig.sinkWidth = refSelectSinkWidth.current.value;

    CheckSinkWidthOptions();

    //Check Sink Position

    if (SinkConfig.singleSink) {
      maxSinkPosition =
        SinkConfig.tableWidth -
        refSelectSinkWidth.current.value / 2 -
        SinkConfig.outerBorder;
    } else {
      maxSinkPosition =
        SinkConfig.tableWidth / 2 -
        refSelectSinkWidth.current.value / 2 -
        SinkConfig.outerBorder * 2 +
        SinkConfig.outerBorder;
    }

    SinkConfig.sinkPos = refInpSinkPos.current.value;
    if (refInpSinkPos.current.value > maxSinkPosition) {
      refInpSinkPos.current.value = maxSinkPosition;
      SinkConfig.sinkPos = refInpSinkPos.current.value;
    }
    if (
      refInpSinkPos.current.value <
      SinkConfig.sinkWidth / 2 + SinkConfig.outerBorder
    ) {
      refInpSinkPos.current.value =
        SinkConfig.sinkWidth / 2 + SinkConfig.outerBorder;
      SinkConfig.sinkPos = refInpSinkPos.current.value;
    }

    reflblSinkWidth.current.innerHTML =
      SinkConfig.minSinkWidth + "-" + maxSinkWidth + " mm";
    refLblSinkPos.current.innerHTML =
      SinkConfig.sinkWidth / 2 +
      SinkConfig.outerBorder +
      "-" +
      maxSinkPosition +
      " mm";

    SCENE.update = true;
  }

  const panes = [
    {
      menuItem: "Dimensionen",
      pane: (
        <Tab.Pane key="1" className="config">
          {tabDimensionen}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Ausstattung",
      pane: (
        <Tab.Pane key="2" className="config">
          {tabAusstattung}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Material",
      pane: <Tab.Pane key="3">{tabMaterials}</Tab.Pane>,
    },
  ];

  return <Tab panes={panes} renderActiveOnly={false} />;
};

export default Tabui;
