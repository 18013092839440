// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\---------dev-sharedUiComponents-dist-components-Icon-modules__light {\n  filter: brightness(10); }\n", "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/Icon.modules.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB,EAAA","sourcesContent":[":local .light {\r\n    filter: brightness(10);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": "---------dev-sharedUiComponents-dist-components-Icon-modules__light"
};
export default ___CSS_LOADER_EXPORT___;
