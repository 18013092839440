import React from "react";
import { Grid } from "semantic-ui-react";
import Cookies from "js-cookie";
import Navbar from "../components/ui/navbar";
import ConfigTable from "../components/ui/configtable";
import FilialTable from "../components/ui/filialtable";
import MitarbeiterTable from "../components/ui/mitarbeitertable";
import MaterialienTable from "../components/ui/materialientable";
import Config from "../Config";
import { getUser } from "../graphql";
import { useQuery } from "@apollo/client";
export default function Dashboard({ settings }) {
  const {
    loading,
    data: userdata,
    error,
  } = useQuery(getUser, {
    variables: { ID: localStorage.getItem("id") },
    
  });
  if (!Cookies.get(Config.TokenName)) {
    return <div>You are not allowed to view this page</div>;
  }

  if (loading) {
    return <div>Loading data...</div>;
  }
  if (error) {
    return <div>Error loading data... {error.message}</div>;
  }
  
  return (
    <div>
      <Navbar settings={settings} />
      <div className="cont">
        <h1>Dashboard</h1>(
        {userdata.usersPermissionsUser.data.attributes.username} -{" "}
        {
          userdata.usersPermissionsUser.data.attributes.role.data.attributes
            .name
        }
        )
        <hr />
        <br />
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <ConfigTable userdata={userdata} />
            </Grid.Column>
            <Grid.Column width={7}>
              {userdata.usersPermissionsUser.data.attributes.role.data
                .attributes.name === "Verwalter" ? (
                <FilialTable />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {userdata.usersPermissionsUser.data.attributes.role.data
                .attributes.name === "Verwalter" ? (
                <MitarbeiterTable />
              ) : null}
            </Grid.Column>
            <Grid.Column width={7}>
              {userdata.usersPermissionsUser.data.attributes.role.data
                .attributes.name === "Verwalter" ? (
                <MaterialienTable />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}
