import "semantic-ui-css/semantic.min.css";
import './components/Scene'
import './App.css';
import Configurator from "./pages/configurator";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useQuery } from '@apollo/client'
import {getLogo, getSettings} from "./graphql"
import TestUpload from "./pages/test";
import CssEditor from "./pages/csseditor";
import Home from "./pages/index";
import { Dimmer, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import  Config  from "./Config";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

//Primeflex CSS
import "/node_modules/primeflex/primeflex.css"
function App() {
  
  const {data} = useQuery(getLogo, {onError: ({ graphQLErrors, networkError, operation, forward }) => {
    
    if(networkError.statusCode === 401){
      Cookies.remove(Config.TokenName)
      window.location.href="/login"
    }
  }})
  const {data:datasettings} = useQuery(getSettings, {onError: ({ graphQLErrors, networkError, operation, forward }) => {
    
    if(networkError.statusCode === 401){
      Cookies.remove(Config.TokenName)
      window.location.href="/login"
    }
  }})
  let logopath=""
  if(data){
    logopath = data.einstellungen.data.attributes.Logo.data.attributes.formats.thumbnail.url
  }
  localStorage.setItem("logo",logopath)
  if(datasettings){
  return (
    <Router>
      <style>{datasettings.einstellungen.data.attributes.css}</style>
    <div className="App">
      <div className="content">
        <Routes>
        <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login settings={datasettings.einstellungen.data.attributes}/>}/>
          <Route path="/configurator" element={<Configurator settings={datasettings.einstellungen.data.attributes}/>}/>
          <Route path="/dashboard" element={<Dashboard settings={datasettings.einstellungen.data.attributes}/>}/>
          <Route path="/cssedit" element={<CssEditor settings={datasettings.einstellungen.data.attributes}/>}/>
          <Route path="/test" element={<TestUpload settings={datasettings.einstellungen.data.attributes}/>}/>
        </Routes>
      
      </div>
    </div>
    </Router>
  );
  }
  return <Dimmer active><Loader content="Lade Daten"/></Dimmer>
}



export default App;
