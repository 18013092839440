import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  createMaterial,
  updateMaterial,
  deleteMaterial,
  getMaterial,
  UPLOAD_IMAGE_MUTATION,
} from "../../graphql";
import {
  Container,
  Form,
  Grid,
  Image,
  Label,
  Button,
  Icon,
} from "semantic-ui-react";
import gql from "graphql-tag";
import UpdateFilialUsers from "./updatefilialusers";
import Config from "../../Config";

const MaterialForm = React.forwardRef((props, ref) => {
  const [isNew, setIsNew] = useState(false);
  const inputFile = React.useRef(null);
  React.useEffect(() => {}, [isNew]);

  //create function to set initial values
  const setInitialValues = (values) => {
    console.log("setInitialValues", values);
    setName(values.Name);
    setBeschreibung(values.Beschreibung);
    setPreis(values.Preis);
    setPoliert(values.Poliert);
    setBild(values.Bild.data.id);
  };

  const [image, setImage] = useState(null);
  const [uploadImage] = useMutation(UPLOAD_IMAGE_MUTATION);
  const handleImageChange = (event) => {
    setImageChanged(true);
    const selectedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const onOpenFile = () => {
    // `current` points to the mounted file input element

    inputFile.current.click();
  };

  const {
    loading: floading,
    error: ferror,
    data: fdata,
  } = useQuery(getMaterial, {
    variables: { ID: props.id },
    onCompleted: (data) => {
      if (props.new === false) {
        setInitialValues(data.material.data.attributes);
        setIsNew(false);
      } else {
        setIsNew(true);
      }
    },
  });
  const [imageChanged, setImageChanged] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [name, setName] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [preis, setPreis] = useState("");
  const [poliert, setPoliert] = useState(false);
  const [bild, setBild] = useState("");
  const [bilddata, setBilddata] = useState("");
  const [checkboxValues, setCheckboxValues] = React.useState({});


  const [updateMaterialMutation] = useMutation(updateMaterial, {
    onCompleted: (newdata) => {
      updateValues(newdata);
    },
  });

  const [createMaterialMutation] = useMutation(createMaterial, {
    onCompleted: (newdata) => {
      updateValues(newdata);
    },
  });

  const updateValues = (newdata) => {
    setName(newdata.updateMaterial.Name);
    setBeschreibung(newdata.updateMaterial.Beschreibung);
    setPreis(newdata.updateMaterial.Preis);
    setPoliert(newdata.updateMaterial.Poliert);
    setBild(newdata.updateMaterial.Bild);
  };

  const handleSubmit = (event) => {
    //event.preventDefault();
    if (imageChanged === true) {
      const byteCharacters = atob(image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
      setBilddata(imageBlob);
      uploadImage({
        variables: { file: imageBlob },
        onCompleted: (newdata) => {
          console.log("newdata", newdata);
          if (isNew === false) {
            updateMaterialMutation({
              variables: {
                ID: props.id,
                Name: name,
                Beschreibung: beschreibung,
                Preis: parseFloat(preis),
                Poliert: poliert,
                Bild: newdata.upload.data.id,
              },
              refetchQueries: [{ query: props.refetch }],
            });
          } else {
            createMaterialMutation({
              variables: {
                Name: name,
                Beschreibung: beschreibung,
                Preis: parseFloat(preis),
                Poliert: poliert,
                Bild: newdata.upload.data.id,
              },
              refetchQueries: [{ query: props.refetch }],
            });
          }
        },
      });
    } else {
      if (isNew === false) {
        updateMaterialMutation({
          variables: {
            ID: props.id,
            Name: name,
            Beschreibung: beschreibung,
            Preis: parseFloat(preis),
            Poliert: poliert,
          },
          refetchQueries: [{ query: props.refetch }],
        });
      } else {
        createMaterialMutation({
          variables: {
            Name: name,
            Beschreibung: beschreibung,
            Preis: parseFloat(preis),
            Poliert: poliert,
          },
          refetchQueries: [{ query: props.refetch }],
        });
      }
    }
  };

  React.useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  React.useEffect(() => {}, [checkboxValues]);

  if (floading) return <p>Loading...</p>;
  if (ferror) {
    console.log(ferror.message)
    return <p>Error :(</p>};
  if (fdata) {
    return (
      <Container>
        <br />
        <Grid>
          <Grid.Column width={4} textAlign={"center"}>
            <h5>Materialbild</h5>
            {props.new ? (
              previewImage ? (
                <Image size="large" src={previewImage} wrapped />
              ) : (
                <Image size="large" src="./no-image.jpg" wrapped />
              )
            ) : previewImage ? (
              <Image size="large" src={previewImage} wrapped />
            ) : (
              <Image
                size="large"
                src={
                  Config.APIURL +
                  fdata.material.data.attributes.Bild.data.attributes.formats
                    .thumbnail.url
                }
                wrapped
              />
            )}

            <br />
            <br />
            <Button
              type="submit"
              onClick={() => onOpenFile()}
              size={"tiny"}
              color={"blue"}
              icon
              labelPosition="left"
            >
              <Icon name="folder open" />
              Bild auswählen
            </Button>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              accept=".jpg, .png, .jpeg"
              onChange={handleImageChange}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Form>
              <Form.Input
                label="Name"
                required={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.TextArea
                label="Beschreibung"
                width={16}
                value={beschreibung}
                onChange={(e) => setBeschreibung(e.target.value)}
              />

              <Form.Input
                label="Preis"
                required={true}
                value={preis}
                onChange={(e) => setPreis(e.target.value)}
              />

              <Form.Group>
                <Form.Checkbox
                  label="Poliert"
                  checked={poliert}
                  onChange={(e) => setPoliert(!poliert)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
  return <></>;
});

export default MaterialForm;
