import React from "react";
import Config from "../Config";
import { Button, Icon, Modal } from "semantic-ui-react";
import SinkConfig from "./SinkConfig";
import { SCENE } from "./Scene";
import Cookies from "js-cookie";
import { getSettings } from "../graphql";
import { useQuery } from "@apollo/client";
import { useRouteLoaderData } from "react-router-dom";

const PDF = (props) => {
  const { loading, error, data: settings } = useQuery(getSettings);
  const [loggedin, setLoggedin] = React.useState(false);
  const [showprice, setShowprice] = React.useState(false);
  React.useEffect(() => {
    if (!Cookies.get(Config.TokenName)) {
      setLoggedin(false);
    } else {
      setLoggedin(true);
    }
  }, []);

  React.useEffect(() => {
    if (settings) {
      console.log("props", props.userdata);
      if (
        !Cookies.get(Config.TokenName) ||
        props.userdata.usersPermissionsUser.data.attributes.Preisanzeigen ===
          false
      ) {
        setLoggedin(false);
      } else {
        setLoggedin(true);
      }
    }
  }, [settings]);
  //const [instance, updateInstance] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Order, setOrder] = React.useState({});
  const logo = Config.APIURL + localStorage.getItem("logo");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  function printdiv(printdivname) {
    // eslint-disable-next-line no-multi-str
    var headstr =
      "<html><head><title>Waschtischdetails</title>\
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css' integrity='sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==' crossorigin='anonymous' referrerpolicy='no-referrer' />\
        </head><body>";

    var footstr = "</body>";
    var newstr = document.getElementById(printdivname).innerHTML;
    //var oldstr = document.body.innerHTML;
    //document.body.innerHTML = headstr+newstr+footstr;
    var html = headstr + newstr + footstr;
    var printWindow = window.open(
      "about:blank",
      "Drucken",
      "left=50000,top=50000,width=0,height=0"
    );
    printWindow.document.write(html);
    var afterPrint = function () {};
    printWindow.onafterprint = afterPrint;
    printWindow.print();
    printWindow.close();
    //document.body.innerHTML = oldstr;

    return false;
  }
  function createOrder() {
    var sinkType = "";
    if (SinkConfig.sinkType === 0) {
      sinkType = "Punktablauf";
    }
    if (SinkConfig.sinkType === 1) {
      sinkType = "Rinnenablauf";
    }
    if (SinkConfig.sinkType === 2) {
      sinkType = "Schlitzablauf";
    }
    var singleSink = "";
    if (SinkConfig.singleSink === 1) {
      singleSink = "Einzelbecken";
    }
    if (SinkConfig.singleSink === 0) {
      singleSink = "Doppelbecken";
    }
    var sinkMontage = "";
    if (SinkConfig.sinkMontage === 1) {
      sinkMontage = "Beckenmontage";
    }
    if (SinkConfig.sinkMontage === 0) {
      sinkMontage = "Wandmontage";
    }

    let preisbox = "";
    preisbox = (
      <div>
        <div className="ui big green label">
          {SinkConfig.endpreisfloat.toFixed(2).toLocaleString()} €
        </div>
        &nbsp; inkl. 19% MwSt*
        <br />
        <small>* zzgl. Verpackung und Versand ab Standort Dinslaken</small>
      </div>
    );
    /* if(SinkConfig.withUnderTable){
          preisbox = (<div>
          <table>
            <tbody>
              <tr>
                <td><b>Waschtisch:</b></td>
                <td>{SinkConfig.basendpreis} €</td>
              </tr>
              <tr>
                <td><b>Untertischablage:</b></td>
                <td>{parseFloat(SinkConfig.ablagenpreis.toFixed(2)).toLocaleString()} €</td>
              </tr>
              
            </tbody>
          </table>
          <b>Gesamtpreis:</b>
          <div className="ui big green label">{SinkConfig.endpreis} €</div>&nbsp; inkl. 19% MwSt*<br />
                <small>* zzgl. Verpackung und Versand ab Standort Dinslaken</small>
          </div>)
        }else{
          preisbox=(<div>
          <div className="ui big green label">{SinkConfig.endpreis} €</div>&nbsp; inkl. 19% MwSt*<br />
          <small>* zzgl. Verpackung und Versand ab Standort Dinslaken</small></div>)
        } */
    let undert = "Nein";
    if (SCENE.showUnderTable) {
      undert = "Ja";
    }
    setOrder({
      tischbreite: SinkConfig.tableWidth,
      tischtiefe: SinkConfig.tableDepth,
      tischhoehe: SinkConfig.tableHeight,
      beckenbreite: SinkConfig.sinkWidth,
      beckentiefe: SinkConfig.beckenTiefe,
      bohrung: sinkMontage,
      beckenposition: SinkConfig.sinkPos,
      einzelbecken: singleSink,
      abflusstyp: sinkType,
      endpreis: SinkConfig.endpreisfloat,
      withundertable: undert,
      orderstatus: 0,
      material: SinkConfig.materialimage,
      previewimage: props.screenshot,
      thumbnail: SinkConfig.thumbnail,
      materialname: SinkConfig.materialname,
      poliert: SinkConfig.materialglossy,
      ablagenpreis: parseFloat(
        (
          SinkConfig.ablagenpreisfloat +
          (SinkConfig.ablagenpreis / 100) * 19
        ).toFixed(2)
      ).toLocaleString(),
      gesamtpreis: SinkConfig.endpreisfloat,
      undertable: SCENE.showUnderTable,
      preisbox: preisbox,
    });
  }

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        SCENE.makeScreenshot = true;
        createOrder();
        setOpen(true);
      }}
      open={open}
      trigger={
        <Button size="mini" className="ui button blue">Zusammenfassung anzeigen</Button>
      }
      id="PDF"
    >
      <Modal.Header>
        <img src={logo} style={{ width: "200px" }} alt="Website Logo" />
        <br />
        Waschtischdetails:
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <table className="ui very basic table">
            <tbody>
              <tr>
                <td>
                  <b>Waschtisch Breite:</b>
                </td>
                <td>{Order.tischbreite} mm</td>

                <td>
                  <b>Waschtisch Tiefe/Höhe:</b>
                </td>
                <td>{Order.tischtiefe} mm / {Order.tischhoehe} mm</td>
              </tr>
              <tr>
                <td>
                  <b>Becken Breite:</b>
                </td>
                <td>{Order.beckenbreite} mm</td>

                <td>
                  <b>Becken Tiefe:</b>
                </td>
                <td>{Order.beckentiefe} mm</td>
              </tr>
              <tr>
                <td>
                  <b>Beckentyp:</b>
                </td>
                <td>{Order.einzelbecken}</td>

                <td>
                  <b>Wasserhahn:</b>
                </td>
                <td>{Order.bohrung}</td>
              </tr>
              <tr>
                <td>
                  <b>Beckenposition:</b>
                </td>
                <td>
                  {Order.beckenposition} mm
                  <br />
                  <small>(Rand links, bis Beckenmitte)</small>
                </td>

                <td>
                  <b>Abflusstyp:</b>
                </td>
                <td>{Order.abflusstyp}</td>
              </tr>

              <tr>
                <td>
                  <b>Untertischablage:</b>
                </td>
                <td>
                  {Order.withundertable}
                  <br />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Material:<br/></b> {Order.materialname}{" "}
                  {Order.poliert ? "poliert" : "matt"}
                  <br />
                </td>
                <td>
                  <img src={Order.thumbnail} alt="Material" />
                </td>

                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="ui very basic table right-aligned">
            <tbody>
              {loggedin ? (
                <tr>
                  <td className="right-aligned">
                    <div style={{ display: "inline-block" }}>
                      <h3 className="ui header">Ihr Preis:</h3>
                    </div>
                  </td>
                  <td className="right-aligned">
                    <div style={{ display: "inline-block" }}>
                      {Order.preisbox}
                    </div>
                  </td>
                </tr>
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
          <br />
          <center>
            <img src={props.screenshot} alt="Screenshot"></img>
          </center>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => printdiv("PDF")}>
          <i className="print icon"></i>Seite drucken
        </Button>
        <Button color="orange" onClick={() => setOpen(false)}>
          Schließen
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PDF;
