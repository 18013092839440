import React from 'react'
import {SCENE} from './Scene'

const Viewswitch = (props) => {
    function handleViewSwitch(val){
    SCENE.viewType = val.target.checked
    SCENE.update = true
    
}
    return (
        <div className="ui toggle checkbox">
            <input type="checkbox" name={props.id} id={props.id} onChange={handleViewSwitch}/>
            <label><i className={props.class}></i> {props.text}</label>
        </div>  
    )
}

export default Viewswitch
