import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { updateFiliale,createFiliale } from "../../graphql";
import { Container, Form, Label } from "semantic-ui-react";
import gql from "graphql-tag";
import UpdateFilialUsers from "./updatefilialusers";

const FilialenForm = React.forwardRef((props, ref) => {
  const [isNew, setIsNew] = useState(false);
  const GET_INITIAL_VALUES = gql`
    query getInitialValues {
      filiale(id: ${props.id}) {
        data {
          id
          attributes {
            Name
            Beschreibung
            Strasse
            PLZ
            Ort
            users {
              data {
                id
                attributes {
                  username
                  Vorname
                  Nachname
                }
              }
            }
          }
        }
      }
    }
  `;

  React.useEffect(() => {
  },[isNew])

  //create function to set initial values
  const setInitialValues = (values) => {
    setName(values.Name);
    setBeschreibung(values.Beschreibung);
    setStrasse(values.Strasse);
    setPlz(values.PLZ);
    setOrt(values.Ort);
    for(let val of values.users.data){
      setCheckboxValues((prevValues) => ({ ...prevValues, [val.id]: true }));

    }
  };

  const {
    loading: floading,
    error: ferror,
    data: fdata,
  } = useQuery(GET_INITIAL_VALUES, {
    onCompleted: (data) => {
      if(props.new === false){setInitialValues(data.filiale.data.attributes);setIsNew(false)} else{setIsNew(true)}
    
    }})
  
  const [name, setName] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [strasse, setStrasse] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [users, setUsers] = useState("");
  const [checkboxValues, setCheckboxValues] = React.useState({});
  const [createFilialMutation] = useMutation(createFiliale, {
    onCompleted: (newdata) => updateValues(newdata),
  });
  
  const [updateFilialeMutation] = useMutation(updateFiliale, {
    onCompleted: (newdata) => updateValues(newdata),
  });
  const updateValues = (newdata) => {
    setName(newdata.updateFiliale.data.attributes.Name);
    setBeschreibung(newdata.updateFiliale.data.attributes.Beschreibung);
    setStrasse(newdata.updateFiliale.data.attributes.Strasse);
    setPlz(newdata.updateFiliale.data.attributes.PLZ);
    setOrt(newdata.updateFiliale.data.attributes.Ort);
    
    
  };

  const handleSubmit = () => {
    //e.preventDefault();
    let userlist = []
    for(const [key, value] of Object.entries(checkboxValues)){
      if(value === true){
        userlist.push(key)
      }
    }
    
    if(isNew === false){
    updateFilialeMutation({
      variables: {
        ID: props.id,
        Name: name,
        Beschreibung: beschreibung,
        Strasse: strasse,
        PLZ: plz,
        Ort: ort,
        Users: userlist,
      },
      refetchQueries: [{ query: props.refetch }],
    });
  }else{
    createFilialMutation({
      variables: {
        Name: name,
        Beschreibung: beschreibung,
        Strasse: strasse,
        PLZ: plz,
        Ort: ort,
        Users: userlist,
      },
      refetchQueries: [{ query: props.refetch }],
    });
  }
  };
  React.useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

 

  if (floading) return <p>Loading...</p>;
  if (ferror) return <p>Error :(</p>;

  

  return (
    <Container>
      <br />
      <Form>
        <Form.Group>
          <Form.Input
            label="Name"
            required={true}
            value={name}
            width={3}
            onChange={(e) => setName(e.target.value)}
          />

          <Form.Input
            label="Strasse"
            required={true}
            value={strasse}
            width={3}
            onChange={(e) => setStrasse(e.target.value)}
          />
          <Form.Input
            label="PLZ"
            required={true}
            value={plz}
            width={3}
            onChange={(e) => setPlz(e.target.value)}
          />
          <Form.Input
            label="Ort"
            required={true}
            value={ort}
            width={3}
            onChange={(e) => setOrt(e.target.value)}
          />
            
        </Form.Group>
        <Form.Group>
        <Form.TextArea label="Beschreibung" width={8} value={beschreibung} onChange={(e) => setBeschreibung(e.target.value)} />
        {/* <Form.Button type="submit" onClick={()=>handleSubmit()} color='blue'>Speichern</Form.Button> */}
        <Form.Field>
          <label>Mitarbeiter</label>
        <UpdateFilialUsers checkboxValues={checkboxValues} setCheckboxValues={setCheckboxValues}/>
        </Form.Field>
        </Form.Group>
      </Form>
    </Container>
  );
});

export default FilialenForm;
