import React from "react";
import Tabui from "./Tab";
import Requestform from "./Requestform";
import PDF from "./PDF";
import { useQuery } from "@apollo/client";
import { getMaterials } from "../graphql";
import Cookies from "js-cookie";
import Config from "../Config";
//import {SCENE} from './Scene'
import { getBiggestImage } from "../helpers";
import SinkConfig from "./SinkConfig";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
const Ui = (props) => {
  const navigate = useNavigate();
  const version = process.env.REACT_APP_VERSION;
  const [imgurl, setImgURL] = React.useState("");
  const [currentMaterial, setCurrentMaterial] = React.useState([]);
  const { loading, error, data } = useQuery(getMaterials);

  function handleNewConfig() {
    console.log("creating new config")
    localStorage.setItem("loadConfig", false);
    localStorage.removeItem("SinkConfig")
    localStorage.setItem("isloaded", false)
    navigate(0);
  }
  if (loading) {
    return <div>Loading....</div>;
  }
  if (error) {
    console.log(error);
    return <div>Fehler beim laden der Daten....</div>;
  }
  if (data) {
    return (
      <div>
        <Tabui
          imgurl={imgurl}
          setImgURL={setImgURL}
          setCurrentMaterial={setCurrentMaterial}
          currentMaterial={currentMaterial}
          materials={data.materials.data}
        />
        <br />
        {localStorage.getItem("loadConfig") === "true" ? (
          <Button
            className="ui button red"
            size="mini"
            onClick={() => handleNewConfig()}
          >
            Neu
          </Button>
        ) : null}
        <Requestform
          screenshot={props.screenshot}
          setScreenshot={props.setScreenshot}
        />
        <PDF
          screenshot={props.screenshot}
          setScreenshot={props.setScreenshot}
          currentMaterial={currentMaterial}
          userdata={props.userdata}
        />
        <div className="or" data-text="/"></div>
        <br />
        <br />
      </div>
    );
  }
};

export default Ui;
