import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { createConfiguration, updateSettings, getSettings, getUser } from "../graphql";
import Config from "../Config";
import StyleEditor from "react-style-editor";
import Navbar from "../components/ui/navbar";
import { Container, Icon, Label, Message } from "semantic-ui-react";
import Cookies from "js-cookie";

function CssEditor({settings}) {

  const [css, setCss] = React.useState(settings.einstellungen.data.attributes.css);
  const [updateSettingsMutation] = useMutation(updateSettings, {
    refetchQueries: [{ query: getSettings }],
  });
  const {data:userdata} = useQuery(getUser, {
    variables: { ID: localStorage.getItem("id") },
  });


  React.useEffect(() => {
    updateSettingsMutation(
      { variables: { css: css } },
      { refetchQueries: [{ query: getSettings }] }
    );
  }, [css]);

  if(userdata){
     

  return (
    <div>
      <div >
        <div>
          <Message info>
            <Label attached="top left" color="teal" size="large">
              <Icon name="exclamation circle" />
              Hinweis
            </Label>

            <Message.Header></Message.Header>
            <small>
              <p>
                Geänderte Werte werden automatisch gespeichert!
                <br />
                Falls einzelne Werte nicht übernommen werden müssen diese mit dem{" "}
                <b>!important</b> Flag versehen werden.
              </p>
            </small>
          </Message>

          <br />
          <div className="csseditordiv">
          <StyleEditor
            defaultValue={css}
            onChange={setCss}
            className="style-editor"
          />
          </div>
        </div>
      </div>
    </div>
  );
  }
  return <div></div>
}

export default CssEditor;
