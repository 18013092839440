var SCENE = {
    update: false,
    viewType: false,
    showUnderTable: false,
    showTableWidthLines: false,
    showTableHeightLines: false,
    showSinkWidthLines: false,
    showSinkPositionLines: false,
    alwaysUpdate: false,
    makeScreenshot: false,
    brightness: 20,
    sunbrightness: 0.5,
    minbrightness: 0,
    maxbrightness: 1200,
    minbrightnesssun: 0,
    maxbrightnesssun: 10,
}
export {SCENE};