import { useQuery, useMutation } from "@apollo/client";
import { getUserConfigurations, deleteConfiguration } from "../../graphql";
import { SCENE } from "../Scene";
import Config from "../../Config";
import {
  Table,
  Header,
  Label,
  Icon,
  Button,
  Form,
  Popup,
  Grid,
} from "semantic-ui-react";
import { convertTimestamp, getBiggestImage } from "../../helpers";
import { Modal, Confirm } from "semantic-ui-react";
import React from "react";
import {
  getSettings,
  updateSettings,
  orderConfiguration,
  getSingleUserConfiguration,
  updateUser,
  getUser
} from "../../graphql";
import { currentConfig } from "../../helpers";
import SinkConfig from "../SinkConfig";
import { Navigate, useNavigate, redirect } from "react-router-dom";

export default function ConfigTable({ userdata }) {
  var navigate = useNavigate();
  const config = currentConfig;
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [showprice, setShowprice] = React.useState(false);
  const [updateSet] = useMutation(updateUser);
  const openModal = (id) => {
    setId(id);
    setOpen(true);
  };
  const { data: settingsdata } = useQuery(getSettings);

  const [orderConfig, { data: orderdata }] = useMutation(orderConfiguration, {
    refetchQueries: [{ query: getUserConfigurations }],
  });

  if (orderdata) {
    console.log("Orderdata: ", orderdata);
  }
  const [confopen, setConfopen] = React.useState(false);
  var tid = { Filter: { id: { eq: userdata.usersPermissionsUser.data.id } } };
  try {
    if (
      userdata.usersPermissionsUser.data.attributes.role.data.attributes
        .name === "Verwalter"
    ) {
      tid = { Filter: {} };
    }
  } catch (e) {
    console.log("Error: ", e);
  }

  const {
    loading: confloading,
    data: confdata,
    error: conferror,
  } = useQuery(getUserConfigurations, { variables: tid });

  const [deleteConfig] = useMutation(deleteConfiguration, {
    refetchQueries: [{ query: getUserConfigurations }],
  });

  function handleOrder() {
    console.log("Ordering");
    orderConfig({ variables: { ID: id, Bestellt: true } });
  }
  function SaveSettings(settings) {
    updateSet(
      { variables: settings },
      { refetchQueries: [{ query: getUser }] }
    );
  }
  React.useEffect(() => {
    if (userdata) {
      setShowprice(userdata.usersPermissionsUser.data.attributes.Preisanzeigen);
    }
  }, [userdata]);

  function handleShowprices() {
    console.log("Writing setting: ", userdata.usersPermissionsUser.data.id)
    SaveSettings({ Preisanzeigen: !showprice, ID: userdata.usersPermissionsUser.data.id });
    setShowprice(!showprice);
  }

  function handleDelete(conf) {
    deleteConfig({ variables: { ID: conf } });
  }
  function handleLoad(conf) {
    
    for (let con of confdata.konfigurationen.data) {
      
      if (con.id === conf) {
        console.log(con);
        SinkConfig.tableWidth = con.attributes.Tischbreite;
        SinkConfig.tableDepth = con.attributes.Tischtiefe;
        SinkConfig.sinkWidth = con.attributes.Beckenbreite;
        SinkConfig.sinkPos = con.attributes.Beckenposition;
        SinkConfig.beckenTiefe = con.attributes.Beckentiefe;
        SinkConfig.sinkMontage = con.attributes.Bohrung;
        SinkConfig.singleSink = con.attributes.Einzelbecken;
        SinkConfig.withUnderTable = con.attributes.Untertischablage;
        SinkConfig.materialname = con.attributes.Material.Name;
        SinkConfig.materialid = con.attributes.Material.matid;
        SinkConfig.materialprice = con.attributes.Material.Preis;
        SinkConfig.materialimage = Config.APIURL + getBiggestImage(con.attributes.Material.Bild.data.attributes.formats);

        console.log(con)
      }
      SCENE.showUnderTable = SinkConfig.withUnderTable;
      localStorage.setItem("SinkConfig", JSON.stringify(SinkConfig))
      localStorage.setItem("loadConfig", "true")
      localStorage.setItem('loadedid', conf)
      
      navigate("/configurator");
      navigate(0)
    }
    //SinkConfig.tableWidth=1400;
    //navigate("/configurator");
  }

  if (confdata) {
    return (
      <div>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Header>
            Konfiguration bestellen:{" "}
            <p className="configdetailheader">
              Kunde: {config.konfiguration.data.attributes.Kunde.Vorname}{" "}
              {config.konfiguration.data.attributes.Kunde.Nachname}
              <br />
              Datum:{" "}
              {new Date(
                config.konfiguration.data.attributes.createdAt
              ).toLocaleDateString()}
              <br />
              Filiale:{" "}
              {
                config.konfiguration.data.attributes.users_permissions_user.data
                  .attributes.filiale.data.attributes.Name
              }
              <br />
              Kundenbetreuer:{" "}
              {
                config.konfiguration.data.attributes.users_permissions_user.data
                  .attributes.Vorname
              }{" "}
              {
                config.konfiguration.data.attributes.users_permissions_user.data
                  .attributes.Nachname
              }
            </p>
          </Modal.Header>

          {/* <Modal.Header>Konfiguration aktualisieren</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <KonfigurationForm
                id={id}
                ref={form}
                refetch={getUserConfigurations}
              />
            </Modal.Description>
          </Modal.Content> */}
          <Modal.Actions>
            <Button
              onClick={() => setConfopen(true)}
              content="Konfiguration bestellen"
              icon="checkmark"
              color="green"
              labelPosition="right"
            />

            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            {/* <Button
              content="Speichern"
              onClick={() => {
                form.current.handleSubmit();
                setOpen(false);
              }}
              color="blue"
            /> */}
          </Modal.Actions>
        </Modal>
        {/* <Header> */}
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header>
                Konfigurationen{" "}
                <Label color="blue" circular>
                  {confdata.konfigurationen.data.length}
                </Label>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <span>
                <Form size={"tiny"}>
                  <Form.Checkbox
                    label="Preise im Konfigurator anzeigen"
                    defaultChecked={showprice}
                    onChange={(event) => handleShowprices(event)}
                  />
                </Form>
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        {/* </Header> */}

        <div className="viewtable">
          <Table striped color="blue">
            <Table.Header>
              <Table.Row>
                <Table.Cell className="cellheader">Filiale</Table.Cell>
                <Table.Cell className="cellheader">Mitarbeiter</Table.Cell>
                <Table.Cell className="cellheader">erstellt am</Table.Cell>
                <Table.Cell className="cellheader">Kunde</Table.Cell>

                <Table.Cell className="cellheader">Endkundenpreis €</Table.Cell>
                <Table.Cell className="cellheader">bestellt:</Table.Cell>
                <Table.Cell className="cellheader cellleftborder">
                  Aktionen
                </Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {confdata.konfigurationen.data.map((conf) => (
                <Table.Row key={conf.id}>
                  <Table.Cell>
                    {conf.attributes.users_permissions_user.data.attributes
                      .filiale.data ? (
                      conf.attributes.users_permissions_user.data.attributes
                        .filiale.data.attributes.Name
                    ) : (
                      <Icon name="ban" size={"big"} color={"red"} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {
                      conf.attributes.users_permissions_user.data.attributes
                        .Vorname
                    }{" "}
                    {
                      conf.attributes.users_permissions_user.data.attributes
                        .Nachname
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {convertTimestamp(conf.attributes.createdAt)}
                  </Table.Cell>
                  <Table.Cell>
                    {conf.attributes.Kunde.Vorname}{" "}
                    {conf.attributes.Kunde.Nachname}
                  </Table.Cell>

                  <Table.Cell>{conf.attributes.PreisMwst} €</Table.Cell>
                  <Table.Cell>
                    {conf.attributes.Bestellt ? (
                      <Icon name="check" color="green" />
                    ) : (
                      <Icon name="close" color="grey" />
                    )}
                  </Table.Cell>
                  <Table.Cell className="cellleftborder">
                    <Popup
                      position="top center"
                      content="Konfiguration bestellen"
                      trigger={
                        <Button
                          color="green"
                          icon
                          size="tiny"
                          onClick={() => openModal(conf.id)}
                        >
                          <Icon name="shop" />
                        </Button>
                      }
                    />
                    <Popup
                      position="top center"
                      content="Konfiguration löschen"
                      trigger={
                        <Button
                          color="red"
                          icon
                          size="tiny"
                          onClick={() => handleDelete(conf.id)}
                        >
                          <Icon name="trash" />
                        </Button>
                      }
                    />
                    {" | "}
                    <Popup
                      position="top center"
                      content="Konfiguration laden"
                      trigger={
                        <Button
                          color="grey"
                          icon
                          size="tiny"
                          onClick={() => handleLoad(conf.id)}
                          
                        >
                          <Icon name="folder open" />
                        </Button>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <Confirm
          open={confopen}
          onCancel={() => {
            setConfopen(false);
          }}
          onConfirm={() => {
            setConfopen(false);
            setOpen(false);
            handleOrder();
          }}
          content="Möchten Sie diese Konfiguration jetzt kostenpflichtig bestellen?"
          cancelButton="Abbrechen"
          confirmButton="Jetzt bestellen"
        />
      </div>
    );
  }
  if (confloading) {
    return <div>Suche Konfigurationen...</div>;
  }
  if (conferror) {
    return <div>Keine Konfigurationen gefunden!</div>;
  }
}
