import React from 'react'
import Config from "../Config"

const Pricetag = (props) => {
    
    return (
        <div className="ui label large orange">
            Preis inkl {Config.MwSt} MwSt: {props.endprice} €
        </div>
    )
}

export default Pricetag
