import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { getUsers, deleteUser, getFilialen } from "../../graphql";
import {
  Table,
  Header,
  Label,
  Icon,
  Button,
  Form,
  Grid,
  Popup,
} from "semantic-ui-react";
import { convertTimestamp } from "../../helpers";
import { Image, Modal, Confirm } from "semantic-ui-react";
import React from "react";
import { getSingleUserConfiguration } from "../../graphql";
import { currentConfig } from "../../helpers";
import Config from "../../Config";
import MitarbeiterForm from "./update_mitarbeiter_form";


export default function MitarbeiterTable() {
  const [configid, setConfigid] = React.useState(-1);
  const [config, setConfig] = React.useState(currentConfig);
  const [isNew, setIsNew] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [id, setId] = React.useState(0)
  const form = React.useRef(null)
  const openModal = (id) => {
    setId(id)
    setOpen(true)

  }
  
  const Tischbreite = React.createRef();
  

  const [confopen,setConfopen] = React.useState(false)
  const {
    loading: confloading,
    data: confdata,
    error: conferror,refetch
  } = useQuery(getUsers);

  const [
    getSingleConfig,
    { data: condata },
  ] = useLazyQuery(getSingleUserConfiguration, {
    variables: { ID: configid },
    onCompleted: () => {
      setConfig(condata);
    },
  });

  const [deleteUserMutation] = useMutation(deleteUser, {
    refetchQueries: [{ query: getUsers }, { query: getFilialen }],
  });


  React.useEffect(() => {
    if (configid !== -1) {
      setOpen(true);
      getSingleConfig();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configid]);

  /* function handletischbreite(event) {
    console.log(config);
    let update = {
      konfiguration: {
        data: { attributes: { Tischbreite: parseInt(event.target.value) } },
      },
    };
    setConfig((config) => ({
      ...config,
      ...update,
    }));
    console.log(config);
  } */

  function saveConfig() {
    setOpen(false);
  }


  function handleDelete(id) {
    deleteUserMutation({ variables: { ID: id } });
  }


  if (confdata) {
    return (
      <div>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          

          {isNew ? (
            <Modal.Header>Mitarbeiter hinzufügen</Modal.Header>
          ) : (
            <Modal.Header>Mitarbeiter aktualisieren</Modal.Header>
          )}
        <Modal.Content>
          <Modal.Description>
            <MitarbeiterForm id={id} ref={form} refetch={getUsers} new={isNew}  />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            Abbrechen
          </Button>
          <Button
            content="Speichern"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {
              form.current.handleSubmit() 
              setOpen(false)
            }}
            positive
          />
        </Modal.Actions>

        </Modal>
        <Header>
        <span><Popup position="right center" content="Neuen Mitarbeiter anlegen" trigger={<Button icon="plus" color="blue" onClick={() => {
              setIsNew(true);
              setOpen(true);  
            }} />} /></span> Mitarbeiter
          <Label color="teal" circular>
            {confdata.usersPermissionsUsers.data.length}
          </Label>
        </Header>
        <div className="viewtable">
        <Table striped color="teal">
          <Table.Header>
            <Table.Row>
              <Table.Cell className="cellheader">Filiale</Table.Cell>
              <Table.Cell className="cellheader">Benutzername</Table.Cell>
              <Table.Cell className="cellheader">Name</Table.Cell>
              <Table.Cell className="cellheader">Rolle</Table.Cell>
              

              
              <Table.Cell className="cellheader">Email</Table.Cell>
              <Table.Cell className="cellheader cellleftborder">Aktionen</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {confdata.usersPermissionsUsers.data.map((conf) => (
              <Table.Row key={conf.id}>
                <Table.Cell>
                  {
                    conf.attributes.filiale.data ?
                    conf.attributes.filiale.data.attributes.Name
                    : <Icon name="ban" color='red' size='big'/>
                  }
                </Table.Cell>
                <Table.Cell>
                  {
                    conf.attributes.username
                  }
                </Table.Cell>
                <Table.Cell>
                  {conf.attributes.Vorname}{" "}{conf.attributes.Nachname}
                </Table.Cell>
                <Table.Cell>{conf.attributes.role.data.attributes.name}</Table.Cell>
                

                <Table.Cell>{conf.attributes.email} </Table.Cell>  
                <Table.Cell className="cellleftborder">
                  <Button
                    color="blue"
                    icon
                    size="tiny"
                    onClick={() => {setIsNew(false); openModal(conf.id)}}
                  >
                    <Icon name="edit"/>
                  </Button>
                  <Button color="red" icon size="tiny" onClick={()=>handleDelete(conf.id)}>
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        </div>
        <Confirm
          open={confopen}
          onCancel={()=>{setConfopen(false)}}
          onConfirm={()=>{setConfopen(false); setOpen(false)}}
          content="Möchten Sie diese Konfiguration jetzt kostenpflichtig bestellen?"
          cancelButton="Abbrechen"
          confirmButton="Jetzt bestellen"
        />
      </div>
    );
  }
  if (confloading) {
    return <div>Suche Konfigurationen...</div>;
  }
  if (conferror) {
    return <div>Keine Konfigurationen gefunden!</div>;
  }
}
