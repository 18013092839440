import Cookies from "js-cookie"
import Config from "../Config"
import { useNavigate } from "react-router-dom";
import React from "react";
export default function Home() {
const navigate = useNavigate();
React.useEffect(()=>{
  if(Cookies.get(Config.TokenName)){
    navigate("/dashboard")
    }else{
    navigate("/login")
    }
})
return <div></div>
}
